package ca.sebleclerc.admin.models

import ca.sebleclerc.core.models.Event

fun Event.Companion.createNew(): Event {
  return Event(
    0,
    "",
    null,
    null,
    null,
    mutableListOf(),
    mutableListOf()
  )
}
