package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableVersion
import ca.sebleclerc.admin.screens.views.VersionEditView
import ca.sebleclerc.admin.viewmodels.VersionEditViewModel

@Composable
fun VersionCreateScreen() {
  val viewModel = VersionEditViewModel(EditableVersion.createNew().version)
  VersionEditView(viewModel)
}
