package ca.sebleclerc.network

import ca.sebleclerc.core.Environment
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Adresse
import ca.sebleclerc.core.models.Contact
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.core.models.Event
import ca.sebleclerc.core.models.EventEdition
import ca.sebleclerc.core.models.EventEditionDay
import ca.sebleclerc.core.models.Image
import ca.sebleclerc.core.models.Version
import ca.sebleclerc.network.models.APIConstants
import ca.sebleclerc.network.models.APIContact
import ca.sebleclerc.network.models.APIEtablissement
import ca.sebleclerc.network.models.APIEvent
import ca.sebleclerc.network.models.APIEventEdition
import ca.sebleclerc.network.models.APIEventEditionDay
import ca.sebleclerc.network.models.APIGroupContact
import ca.sebleclerc.network.models.APIImage
import ca.sebleclerc.network.models.APIResponse
import ca.sebleclerc.network.models.APIValidation
import ca.sebleclerc.network.models.APIVersion
import ca.sebleclerc.network.models.LatLongResponse
import ca.sebleclerc.network.models.toAPI
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.engine.HttpClientEngineConfig
import io.ktor.client.engine.HttpClientEngineFactory
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.patch
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.HttpMethod
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

expect val HttpEngineFactory: HttpClientEngineFactory<HttpClientEngineConfig>

class APIService(private var environment: Environment) : IAPIService {
  // Private val

  private lateinit var client: HttpClient
  private val format = Json {
    ignoreUnknownKeys = true
    isLenient = true
  }

  // IAPIService

  init {
    SharedLogger.info("APIService - init")

    try {
      client = HttpClient()
    } catch (ex: Exception) {
      SharedLogger.error("Exception in APIService init")
      SharedLogger.error(ex.toString())
    }
  }

  // Common

  override fun setEnvironment(env: Environment) {
//    environment = env
    environment = Environment.Local
  }

  // Events

  override suspend fun getEvents(): List<APIEvent> {
    return fetchAndParse("/events")
  }

  override suspend fun getEventWithId(id: Int): APIEvent? {
    return fetchAndParse("/events/$id")
  }

  override suspend fun getEventEditions(id: Int): List<APIEventEdition> {
    return fetchAndParse("/events/$id/editions")
  }

  override suspend fun getEventContacts(eventId: Int): List<APIContact> {
    return fetchAndParse("/events/$eventId/contacts")
  }

  override suspend fun getEventImages(eventId: Int): List<APIImage> {
    return fetchAndParse("/events/$eventId/images")
  }

  override suspend fun getEditions(): List<APIEventEdition> {
    return fetchAndParse("/editions")
  }

  override suspend fun getYearlyEditions(year: String): List<APIEventEdition> {
    return fetchAndParse("/editions?year=$year")
  }

  override suspend fun getEventEditionDays(): List<APIEventEditionDay> {
    return fetchAndParse("/eventDays")
  }

  override suspend fun getYearlyEditionDays(year: String): List<APIEventEditionDay> {
    return fetchAndParse("/eventDays?year=$year")
  }

  override suspend fun getSingleEventEdition(eventId: Int, editionId: Int): APIEventEdition? {
    return fetchAndParse("/events/$eventId/editions/$editionId")
  }

  override suspend fun getEventEditionDays(eventId: Int, editionId: Int): List<APIEventEditionDay> {
    return fetchAndParse("/events/$eventId/editions/$editionId/days")
  }

  override suspend fun createEvent(ev: Event): Int? {
    val serialized = Json.encodeToString(ev.toAPI())
    return createOrUpdate(true, "/events", serialized)
  }

  override suspend fun updateEvent(id: Int, ev: Event): Boolean {
    val serialized = Json.encodeToString(ev.toAPI())
    return createOrUpdate(false, "/events/$id", serialized)
  }

  override suspend fun createEventContact(id: Int, contact: Contact): Boolean {
    return createAssociatedContact(contact, "/events/$id/contacts")
  }

  override suspend fun createEventImage(id: Int, image: Image): Boolean {
    return createAssociatedImage(image, "/events/$id/images")
  }

  override suspend fun associateEventContact(id: Int, contactId: Int): Boolean {
    return associateEntities("/events/$id/contacts/$contactId")
  }

  override suspend fun associateEventImage(id: Int, imageId: Int): Boolean {
    return associateEntities("/events/$id/images/$imageId")
  }

  override suspend fun createEventEdition(eventId: Int, ed: EventEdition): Int? {
    val serialized = Json.encodeToString(ed.toAPI())
    return createOrUpdate(true, "/events/$eventId/editions", serialized)
  }

  override suspend fun updateEventEdition(editionId: Int, ed: EventEdition): Boolean {
    val serialized = Json.encodeToString(ed.toAPI())
    return createOrUpdate(false, "/editions/$editionId", serialized)
  }

  override suspend fun createEventEditionDay(
    eventId: Int,
    editionId: Int,
    day: EventEditionDay
  ): Int? {
    val serialized = Json.encodeToString(day.toAPI())
    return createOrUpdate(
      true,
      "/events/$eventId/editions/$editionId/days",
      serialized
    )
  }

  override suspend fun updateEventEditionDay(dayId: Int, day: EventEditionDay): Boolean {
    val serialized = Json.encodeToString(day.toAPI())
    return createOrUpdate(false, "/days/$dayId", serialized)
  }

  // Places

  override suspend fun getEtablissements(
    showAll: Boolean,
    showTravel: Boolean
  ): List<APIEtablissement> {
    var endpoint = "/etablissements"
    val params = mutableListOf<String>()

    if (showAll) {
      params.add("show_all=true")
    }
    if (showTravel) {
      params.add("show_travel=true")
    }

    if (params.isNotEmpty()) {
      endpoint += "?"
      endpoint += params.joinToString("&")
    }

    try {
      return fetchAndParse(endpoint)
    } catch (ex: Exception) {
      SharedLogger.error("Exception while getEtablissements()")
      SharedLogger.error(ex.toString())
    }

    return emptyList()
  }

  override suspend fun getEtablissementsForGroup(id: Int): List<APIEtablissement> {
    return fetchAndParse("/etablissements/groups/$id")
  }

  override suspend fun getEtablissementWithId(id: Int): APIEtablissement? {
    return fetchAndParse("/etablissements/$id")
  }

  override suspend fun getEtablissementContacts(id: Int): List<APIContact> {
    return fetchAndParse("/etablissements/$id/contacts")
  }

  override suspend fun getEtablissementImages(id: Int): List<APIImage> {
    return fetchAndParse("/etablissements/$id/images")
  }

  override suspend fun createEtablissement(etablissement: Etablissement): Int? {
    val serialized = Json.encodeToString(etablissement.toAPI())
    return createOrUpdate(true, "/etablissements", serialized)
  }

  override suspend fun updateEtablissement(id: Int, etablissement: Etablissement): Boolean {
    val serialized = Json.encodeToString(etablissement.toAPI())
    return createOrUpdate(false, "/etablissements/$id", serialized)
  }

  override suspend fun createEtablissementContact(id: Int, contact: Contact): Boolean {
    return createAssociatedContact(contact, "/etablissements/$id/contacts")
  }

  override suspend fun createEtablissementImage(id: Int, image: Image): Boolean {
    return createAssociatedImage(image, "/etablissements/$id/images")
  }

  override suspend fun associateEtablissementContact(id: Int, contactId: Int): Boolean {
    return associateEntities("/etablissements/$id/contacts/$contactId")
  }

  override suspend fun associateEtablissementImage(id: Int, imageId: Int): Boolean {
    return associateEntities("/etablissements/$id/images/$imageId")
  }

  // Contacts

  override suspend fun getAllContacts(): List<APIContact> {
    return fetchAndParse("/contacts")
  }

  override suspend fun getContactsForGroup(id: Int): List<APIGroupContact> {
    return fetchAndParse("/etablissements/groups/$id/contacts")
  }

  override suspend fun createContact(contact: Contact): Int? {
    val serialized = Json.encodeToString(contact.toAPI())
    return createOrUpdate(true, "/contacts", serialized)
  }

  override suspend fun updateContact(id: Int, contact: Contact): Boolean {
    val serialized = Json.encodeToString(contact.toAPI())
    return createOrUpdate(false, "/contacts/$id", serialized)
  }

  // Versions

  override suspend fun getLatestVersion(): APIVersion {
    return fetchAndParse("/versions/latest")
  }

  override suspend fun getAllVersions(): List<APIVersion> {
    return fetchAndParse("/versions")
  }

  override suspend fun createVersion(version: Version): Int? {
    val serialized = Json.encodeToString(version.toAPI())
    return createOrUpdate(true, "/versions", serialized)
  }

  // Images

  override suspend fun getAllImages(): List<APIImage> {
    return fetchAndParse("/images")
  }

  override suspend fun createImage(image: Image): Int? {
    val serialized = Json.encodeToString(image.toAPI())
    return createOrUpdate(true, "/images", serialized)
  }

  override suspend fun updateImage(imageId: Int, image: Image): Boolean {
    val serialized = Json.encodeToString(image.toAPI())
    return createOrUpdate(false, "/images/$imageId", serialized)
  }

  // Validations

  override suspend fun getAllValidations(): List<APIValidation> {
    return fetchAndParse("/validations")
  }

  // Others

  override suspend fun getLatLong(adresse: Adresse): Pair<String, String> {
    val rue = adresse.rue.split(' ').joinToString("+").replace(".", "")
    val ville = adresse.ville.split(' ').joinToString("+")
    val queryAdresse = "address=${adresse.numCiv}+$rue,+$ville,+QC"
    val url = "https://maps.googleapis.com/maps/api/geocode/json?$queryAdresse&key=AIzaSyB9pazhF7a2oEFND3gFMuJW3hc1FQBuzy8" // ktlint-disable max-line-length
    val response = client.get { url(url) }.body<String>()
    val parsed = format.decodeFromString<LatLongResponse>(response)

    val location = parsed.results[0].geometry.location
    return Pair(location.lat.toString(), location.lng.toString())
  }

  // Private fun

  private suspend inline fun <reified T> fetchAndParse(endpoint: String): T {
    SharedLogger.debug("fetchAndParse for endpoint $endpoint")
    val response = queryAPI(endpoint)
    val apiResponse = format.decodeFromString<APIResponse<T>>(response)

    return apiResponse.content
  }

  private suspend fun queryAPI(endpoint: String): String {
    SharedLogger.info("APIService queryAPI $endpoint")
    val response = client.get {
      url(environment.baseApiUrl + endpoint)
      header(
        APIConstants.headerToken,
        "HQGoyzaffJrXktxZx,WAzXqbyi/4rBLNMuPyjgsUHjckdDiqxXLY8YMPuxZr=byG"
      )
      // from BiereAPI
      // private val accessToken = "jpLEY@dWEVdmaBPi(fBoXCbkHReTPPAHJ6urzhZcmTdYAWF8YVBgMQPrEumKxnrg"
      // Seems to be read only token
    }

    return response.body()
  }

  private suspend inline fun <reified T> createOrUpdate(
    isCreating: Boolean,
    endpoint: String,
    body: String
  ): T {
    val response = client.request {
      method = if (isCreating) HttpMethod.Put else HttpMethod.Post
      url(environment.baseApiUrl + endpoint)
      header(
        APIConstants.headerToken,
        "HQGoyzaffJrXktxZx,WAzXqbyi/4rBLNMuPyjgsUHjckdDiqxXLY8YMPuxZr=byG"
      )
      header(APIConstants.headerContentType, APIConstants.headerTypeJson)
      setBody(body)
    }

    val responseBody = response.body<String>()
    val apiResponse = format.decodeFromString<APIResponse<T>>(responseBody)

    return apiResponse.content
  }

  private suspend fun createAssociatedContact(contact: Contact, endpoint: String): Boolean {
    val serialized = Json.encodeToString(contact.toAPI())
    return createAssociatedEntity(serialized, endpoint)
  }

  private suspend fun createAssociatedImage(image: Image, endpoint: String): Boolean {
    val serialized = Json.encodeToString(image.toAPI())
    return createAssociatedEntity(serialized, endpoint)
  }

  private suspend fun createAssociatedEntity(json: String, endpoint: String): Boolean {
    // Special case
    val response = client.request {
      method = HttpMethod.Put
      url(environment.baseApiUrl + endpoint)
      header(
        APIConstants.headerToken,
        "HQGoyzaffJrXktxZx,WAzXqbyi/4rBLNMuPyjgsUHjckdDiqxXLY8YMPuxZr=byG"
      )
      header(APIConstants.headerContentType, APIConstants.headerTypeJson)
      setBody(json)
    }

    val responseBody = response.body<String>()
    val apiResponse = format.decodeFromString<APIResponse<Boolean>>(responseBody)

    return apiResponse.content
  }

  private suspend fun associateEntities(endpoint: String): Boolean {
    val response = client.patch {
      url(environment.baseApiUrl + endpoint)
      header(
        APIConstants.headerToken,
        "HQGoyzaffJrXktxZx,WAzXqbyi/4rBLNMuPyjgsUHjckdDiqxXLY8YMPuxZr=byG"
      )
      header(APIConstants.headerContentType, APIConstants.headerTypeJson)
    }

    val responseBody = response.body<String>()
    val apiResponse = format.decodeFromString<APIResponse<Boolean>>(responseBody)

    return apiResponse.content
  }
}
