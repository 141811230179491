package ca.sebleclerc.core.models

class EventEdition(
  var id: Int,
  var idEvent: Int,
  var adresse: Adresse?,
  var year: Int,
  var startDateYear: Int,
  var startDateMonth: Int,
  var startDateDay: Int,
  var endDateYear: Int,
  var endDateMonth: Int,
  var endDateDay: Int,
  var informations: String?,
  var statut: Int
) {

  companion object {}

  val displayDates: String
    get() {
      return when {
        isOneDay -> startDate.YMDstring
        else -> YMDIntervalString
      }
    }

  val startDate: Day
    get() = Day(startDateYear, startDateMonth, startDateDay)

  val endDate: Day
    get() = Day(endDateYear, endDateMonth, endDateDay)

  val YMDIntervalString: String
    get() {
      if (startDate.compareTo(endDate) == 0) {
        return startDate.YMDstring
      } else {
        return "${startDate.YMDstring} - ${endDate.YMDstring}"
      }
    }

  val isOneDay: Boolean
    get() = startDate.compareTo(endDate) == 0
}
