package ca.sebleclerc.core

import kotlin.js.Date

internal actual fun logMessage(level: LogLevel, message: String) {
  var logMessage: String = when (level) {
    LogLevel.ERROR -> "❗"
    LogLevel.WARNING -> "⚠️"
    LogLevel.DEBUG -> "\uD83D\uDFE9"
    LogLevel.INFO -> "\uD83D\uDD37"
  }

  val now = Date(Date.now()).toISOString()

  logMessage += " [Shared42] $now $message"

  console.log(logMessage)
}
