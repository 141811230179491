package ca.sebleclerc.core.models

data class Etablissement(
  var id: Int,
  var nom: String,
  var nom_alternatif: String?,
  var idTypeEtablissement: Int,
  var idGroup: Int?,
  var notes: String?,
  var contacts: MutableList<Contact>,
  var images: MutableList<Image>,
  var adresse: Adresse?,
  var access: EtablissementAccess?,
  var statut: Int
) {
  companion object {}

  val displayName: String
    get() = nom_alternatif ?: nom

  val typeEtablissement: TypeEtablissement
    get() {
      return TypeEtablissement.valueOf(idTypeEtablissement)
        ?: TypeEtablissement.Inconnu
    }

  val websiteContact: Contact?
    get() = getContactFor(TypeContact.SiteWeb)

  val emailContact: Contact?
    get() = getContactFor(TypeContact.Courriel)

  val facebookContact: Contact?
    get() = getContactFor(TypeContact.Facebook)

  val twitterContact: Contact?
    get() = getContactFor(TypeContact.Twitter)

  val instagramContact: Contact?
    get() = getContactFor(TypeContact.Instagram)

  val telephoneContact: Contact?
    get() = getContactFor(TypeContact.NumTelephone)

  val logoImage: Image?
    get() = getImageFor(ImageType.Logo)

  private fun getContactFor(typeContact: TypeContact): Contact? {
    for (contact in contacts) {
      if (contact.typeContact == typeContact) {
        return contact
      }
    }

    return null
  }

  private fun getImageFor(type: ImageType): Image? {
    for (image in images) {
      if (image.type == type) {
        return image
      }
    }

    return null
  }
}
