package ca.sebleclerc.core.models

import kotlin.js.JsExport

@JsExport
data class Adresse(
  var id: Int,
  var nom: String?,
  var numCiv: String,
  var rue: String,
  var local: String?,
  var ville: String,
  var province: String,
  var pays: String,
  var codePostal: String,
  var latitude: String,
  var longitude: String,
  var notes: String?,
  var statut: Int
) {
  companion object {}

  val displayText: String?
    get() {
      var text = ""

      if (nom != null) {
        text += "${nom!!}\n"
      }

      text += "$numCiv $rue"

      if (local != null) {
        text += "\n${local!!}"
      }

      text += "\n$ville, $province\n${pays}\n$codePostal"

      return text
    }
}
