package ca.sebleclerc.admin.screens.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Modal
import ca.sebleclerc.admin.components.AdminTextField
import ca.sebleclerc.admin.components.ContactEditView
import ca.sebleclerc.admin.components.EtablissementsListSingleView
import ca.sebleclerc.admin.components.SubtitleView
import ca.sebleclerc.admin.components.ui.Column
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.EtablissementsGroupViewModel
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Tr

@Composable
fun EtablissementsGroupView(viewModel: EtablissementsGroupViewModel) {
  H1 { Text("Groupe ${viewModel.places.first().nom}") }

  Row {
    AdminTextField("Nom", viewModel.sharedNameField)
  }

  SubtitleView("Établissements")

  Row {
    Button("Create new") {
      GlobalScope.launch { viewModel.createNew() }
    }
  }

  Row {
    Column {
      Table {
        Tr {
          Th { Text("ID") }
          Th { Text("Nom") }
          Th { Text("Inactif?") }
          Th { Text("Actions") }
        }

        viewModel.places.forEach {
          EtablissementsListSingleView(it)
        }
      }
    }
  }

  SubtitleView("Shared Contacts")


    viewModel
      .groupContacts
      .sortedWith(compareBy({ it.first.idTypeContact.value }, { it.first.id }))
      .forEach {
        Row {
          Column {
            Row {
              Text("Number of places: ${it.second.nbEtablissements}")
              Button("Assign") { viewModel.didClickGroupAssignContact(it.first.id) }
            }
            Row {
              ContactEditView(it.first)
            }
          }
        }
      }

  Row {
    Button("Save") {
      GlobalScope.launch { viewModel.save() }
    }
  }

  if (viewModel.showGroupAssignModal) {
    Modal(
      "Assign Contact",
      onDismissRequest = { viewModel.showGroupAssignModal = false },
      footer = {
        Button("Assign") { viewModel.assign() }
        Button("Close") { viewModel.showGroupAssignModal = false }
      }
    ) {
      Span({ style { paddingRight(20.px) } }) { Text("Place IDs:") }
      TextInput(value = viewModel.groupAssignPlaceIds, attrs = {
        onInput {
          viewModel.groupAssignPlaceIds = it.value
        }
      })
    }
  }
}