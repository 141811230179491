package ca.sebleclerc.admin.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.models.EditableContact
import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.admin.models.createNew
import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.network.models.APIGroupContact
import ca.sebleclerc.network.models.CreateOrUpdateResult
import ca.sebleclerc.network.models.toDomain
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

typealias GroupContact = Pair<EditableContact, APIGroupContact>

class EtablissementsGroupViewModel(val places: List<Etablissement>,
                                   contacts: List<APIGroupContact>,
                                   val reload: () -> Unit
) : SharedViewModel(reload), KoinComponent {
  val groupContacts = mutableStateListOf<GroupContact>()
  private val repo: EtablissementsRepositoryable by inject()

  var showGroupAssignModal by mutableStateOf(false)
  var groupAssignPlaceIds by mutableStateOf("")
  private var groupAssignContactId: Int? = null

  private var sharedName = places.first().nom
  var sharedNameField = EditableField(sharedName) { sharedName = it }

  init {
    val grouped = contacts
      .map { GroupContact(
        EditableContact(it.contact.toDomain()),
        it
      ) }

    SharedLogger.debug("Number of contacts? ${grouped.count()}")
    groupContacts.addAll(grouped)
  }

  suspend fun save() {
    val responses = mutableListOf<Boolean?>()

    if (sharedNameField.hasChanged) {
      for (place in places) {
        place.nom = sharedName

        when (val response = repo.createOrUpdateEtablissement(place)) {
          is CreateOrUpdateResult.Created -> {
            SharedLogger.warning("Should not happen")
          }

          is CreateOrUpdateResult.Updated -> {
            responses.add(response.success)
          }
        }
      }
    }

    groupContacts.forEach {
      // For new contacts, create then assign
      // TODO implement

      // For changes only, just update
      if (it.first.isNew.not() && it.first.hasChanged) {
        responses.add(contactRepo.updateContact(it.first.contact))
      }
    }

    finalizeCreateOrUpdate(
      SharedRelation.Etablissement(0),
      responses,
      false
    )
  }

  suspend fun createNew() {
    val newPlace = Etablissement.createNew()
    newPlace.nom = places.first().nom
    newPlace.nom_alternatif = places.first().nom
    newPlace.idTypeEtablissement = places.first().idTypeEtablissement
    newPlace.idGroup = places.first().idGroup

    when (val result = repo.createOrUpdateEtablissement(newPlace)) {
      is CreateOrUpdateResult.Created -> {
        window.location.href = "#/etablissements/${result.newId}"
      }
      else -> window.alert("Should not happen \uD83E\uDD14")
    }
  }

  fun didClickGroupAssignContact(contactId: Int) {
    groupAssignContactId = contactId
    showGroupAssignModal = true
  }

  fun assign() {
    val placeIds = groupAssignPlaceIds.split(",").mapNotNull { it.toIntOrNull() }
    groupAssignContactId?.also {
      assignContactToPlaces(it, placeIds)
    }
  }

  private fun assignContactToPlaces(contactId: Int, placeIds: List<Int>) {
    val responses = mutableListOf<Boolean>()

    GlobalScope.launch {
      for (placeId in placeIds) {
        val success = contactRepo.assignContact(SharedRelation.Etablissement(placeId), contactId.toString())
        responses.add(success)
      }

      if (responses.contains(false)) {
        showFailureConfirmation(false)
      } else {
        reload()
      }
    }
  }
}