package ca.sebleclerc.admin.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.toMutableStateList
import ca.sebleclerc.admin.models.EditableAdresse
import ca.sebleclerc.admin.models.EditableContact
import ca.sebleclerc.admin.models.EditableEvent
import ca.sebleclerc.admin.models.EditableEventEdition
import ca.sebleclerc.admin.models.EditableImage
import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Event
import ca.sebleclerc.core.models.EventEdition
import ca.sebleclerc.network.models.CreateOrUpdateResult
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class EventEditViewModel(event: Event, evEditions: List<EventEdition>, reload: () -> Unit) : SharedViewModel(reload), KoinComponent {
  private val repo: EventsRepositoryable by inject()

  var event by mutableStateOf(EditableEvent(event))
  var editions = mutableStateListOf<EditableEventEdition>()

  init {
    event.adresse?.also {
      adresse = EditableAdresse(it)
    }

    editions.addAll(evEditions.map { EditableEventEdition(it) })
    contacts.addAll(event.contacts.map { EditableContact(it) })
    images.addAll(event.images.map { EditableImage(it) })
  }

  // Editions

  fun addEdition() {
    window.location.href = "#/events/${event.id}/editions/new"
  }

  // Saving

  suspend fun createOrUpdate() {
    SharedLogger.debug("createOrUpdate")
    val responses = mutableListOf<Boolean?>()
    var creating = false

    if (shouldUpdateEvent()) {
      var updatedEvent = event.event
      updatedEvent.adresse = adresse?.adresse

      val result = repo.createOrUpdateEvent(updatedEvent)
      SharedLogger.debug("Received response $result")

      when (result) {
        is CreateOrUpdateResult.Created -> {
          creating = true

          result.newId?.also {
            responses.add(true)
            event.event.id = it
          } ?: run {
            responses.add(false)
          }
        }

        is CreateOrUpdateResult.Updated -> {
          creating = false
          responses.add(result.success)
        }
      }
    }

    if (false in responses) {
      showFailureConfirmation(creating)
      return
    }

    // update contacts
    responses.addAll(updateContacts(SharedRelation.Event(event.id)))

    if (false in responses) {
      showFailureConfirmation(creating)
      return
    }

    // update images
    responses.addAll(updateImages(SharedRelation.Event(event.id)))

    finalizeCreateOrUpdate(
      SharedRelation.Event(event.id),
      responses,
      creating
    )
  }

  private fun shouldUpdateEvent(): Boolean {
    if (event.shouldBeUpdated) {
      return true
    }

    if (adresse?.shouldBeUpdated == true) {
      return true
    }

    return false
  }

  // Shared

  override fun didFinishAssignContact() {
    assignContact(SharedRelation.Event(event.id))
  }

  override fun didFinishAssignImage() {
    assignImage(SharedRelation.Event(event.id))
  }
}