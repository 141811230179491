package ca.sebleclerc.admin.models.editables

open class EditableModel(val isNew: Boolean = false) {
  var fields = listOf<EditableField<*>>()

  val shouldBeUpdated: Boolean
    get() {
      return isNew || hasChanged
    }

  val hasChanged: Boolean
    get() {
      return fields.any { it.hasChanged }
    }
}
