package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.createNew
import ca.sebleclerc.admin.screens.views.EtablissementEditView
import ca.sebleclerc.admin.viewmodels.EtablissementEditViewModel
import ca.sebleclerc.core.models.Etablissement

@Composable
fun EtablissementCreateScreen() {
  val viewModel = EtablissementEditViewModel(Etablissement.createNew(), {})
  EtablissementEditView(viewModel)
}
