package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Version

class EditableVersion(val version: Version, isNew: Boolean = false) : EditableModel(isNew) {
  val major = EditableField(version.major) { version.major = it }
  val minor = EditableField(version.minor) { version.minor = it }
  val build = EditableField(version.build) { version.build = it }
  val scriptName = EditableField(version.scriptName) { version.scriptName = it }

  init {
    fields = listOf(
      major,
      minor,
      build,
      scriptName
    )
  }

  val id: Int
    get() { return version.id }

  companion object {
    fun createNew(): EditableVersion {
      return EditableVersion(
        Version(
          0,
          0,
          0,
          0,
          ""
        ),
        isNew = true
      )
    }
  }
}
