package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Etablissement

class EditableEtablissement(
  var etablissement: Etablissement,
  isNew: Boolean = false
) : EditableModel(isNew) {
  val nom = EditableField(etablissement.nom) { etablissement.nom = it }
  val nomAlternatif = EditableField(etablissement.nom_alternatif) {
    etablissement.nom_alternatif = it
  }
  val idTypeEtablissement = EditableField(etablissement.idTypeEtablissement) {
    etablissement.idTypeEtablissement = it
  }
  val idGroup = EditableField(etablissement.idGroup) { etablissement.idGroup = it }
  val notes = EditableField(etablissement.notes) { etablissement.notes = it }
  val statut = EditableField(etablissement.statut) { etablissement.statut = it }

  init {
    fields = listOf(
      nom,
      nomAlternatif,
      idTypeEtablissement,
      idGroup,
      notes,
      statut
    )
  }

  val id: Int
    get() { return etablissement.id }
}
