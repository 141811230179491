package ca.sebleclerc.admin.viewmodels

import ca.sebleclerc.admin.models.EditableVersion
import ca.sebleclerc.admin.repositories.VersionsRepositoryable
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Version
import ca.sebleclerc.network.models.CreateOrUpdateResult
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class VersionEditViewModel(version: Version) : KoinComponent {
  val repo: VersionsRepositoryable by inject()
  var version = EditableVersion(version)

  fun createOrUpdate() {
    SharedLogger.debug("Versions - createOrUpdate")

    GlobalScope.launch {
      when (val result = repo.createOrUpdateVersion(version.version)) {
        is CreateOrUpdateResult.Created -> showConfirmation(result.newId != null)
        is CreateOrUpdateResult.Updated -> showConfirmation(result.success)
      }
    }
  }

  private suspend fun showConfirmation(success: Boolean) {
    if (success) {
      repo.loadData()
      window.alert("Success!")
      window.location.href = "#/versions"
    } else {
      window.alert("Oops! Une erreur :(")
    }
  }
}
