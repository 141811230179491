package ca.sebleclerc.admin.screens.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import ca.sebleclerc.admin.components.AdminIDField
import ca.sebleclerc.admin.components.AdminNullTextField
import ca.sebleclerc.admin.components.AdminNumberField
import ca.sebleclerc.admin.components.AdresseEditView
import ca.sebleclerc.admin.components.EventEditionDayEditView
import ca.sebleclerc.admin.components.SubtitleView
import ca.sebleclerc.admin.components.TitleView
import ca.sebleclerc.admin.components.ui.FlexContainer
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.EventEditionEditViewModel
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun EventEditionEditView(viewModel: EventEditionEditViewModel) {
  Div({ style { height(20.px) } })

  FlexContainer {
    TitleView("Event Edition")

    Row {
      A(href = "#/events/${viewModel.edition.idEvent.value}") {
        Text("Back to Event")
      }
    }

    Row {
      AdminIDField(viewModel.edition.id)
      AdminNumberField("Year", viewModel.edition.year)
    }

    Row {
      AdminNumberField("StartYear", viewModel.edition.startDateYear)
      AdminNumberField("StartMonth", viewModel.edition.startDateMonth)
      AdminNumberField("StartDay", viewModel.edition.startDateDay)
    }

    Row {
      AdminNumberField("EndDay", viewModel.edition.endDateYear)
      AdminNumberField("EndMonth", viewModel.edition.endDateMonth)
      AdminNumberField("EndDay", viewModel.edition.endDateDay)
    }

    Row {
      AdminNullTextField("Informations", viewModel.edition.informations)
    }

    SubtitleView("Adresse")

    Row {
      if (viewModel.adresse != null) {
        AdresseEditView(viewModel)
      } else {
        Button("Add Adresse") {
          viewModel.addAddress()
        }
      }
    }

    SubtitleView("Days")

    Row {
      Button("Add Day") {
        viewModel.addDay()
      }
    }

    viewModel.days.forEach {
      Row {
        EventEditionDayEditView(it)
      }
    }

    Row {
      Button("Save") {
        GlobalScope.launch { viewModel.createOrUpdate() }
      }
    }
  }
}
