package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

enum class IconType(val text: String, val color: CSSColorValue?) {
  ADD("add_circle_outline", null),
  DELETED("highlight_off", Color.red),
  EDIT("mode_edit", null),
  PASSED("check", Color.green)
}

@Composable
fun Icon(type: IconType) {
  Span({
    classes("material-icons")
    style {
      type.color?.also {
        color(it)
      }
    }
  }) {
    Text(type.text)
  }
}