package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import app.softwork.bootstrapcompose.Table
import ca.sebleclerc.admin.repositories.VersionsRepositoryable
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin

@Composable
fun VersionListScreen(koin: Koin) {
  val repo = koin.get<VersionsRepositoryable>()
  val versions = repo.versions.collectAsState()

  H1 { Text("Versions") }

  if (versions.value.isNotEmpty()) {
    Table(
      data = versions.value,
      stripedRows = true
    ) { _, version ->
      column(title = "ID") { Text("${version.id}") }
      column(title = "Major") { Text("${version.major}") }
      column(title = "Minor") { Text("${version.minor}") }
      column(title = "Build") { Text("${version.build}") }
    }
  } else {
    Text("Loading...")
  }
}
