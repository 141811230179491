package ca.sebleclerc.network.models

import kotlinx.serialization.Serializable

@Serializable
data class APIResponse<T>(
  val code: Int,
  val message: String,
  val content: T
) {
  companion object {
    fun <T> withContent(content: T): APIResponse<T> {
      return APIResponse(
        200,
        "All good!",
        content
      )
    }

    fun withSuccess(success: Boolean): APIResponse<Boolean> {
      if (success) {
        return APIResponse(
          200,
          "Success",
          true
        )
      } else {
        return APIResponse(
          400,
          "Failed",
          false
        )
      }
    }
  }
}
