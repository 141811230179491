package ca.sebleclerc.admin.components

import ca.sebleclerc.core.models.DayOfWeek
import ca.sebleclerc.core.models.ImageType
import ca.sebleclerc.core.models.TypeContact

val actifSelectValues: List<AdminSelectOption>
  get() = listOf(
    AdminSelectOption(1, "Actif"),
    AdminSelectOption(0, "Disabled")
  )

val dayOfWeekValues: List<AdminSelectOption>
  get() = DayOfWeek.all.map { AdminSelectOption(it.dayIndex, it.name) }

val imageTypeValues: List<AdminSelectOption>
  get() = ImageType.values().map { AdminSelectOption(it.ordinal, it.name) }

val typeContactValues: List<AdminSelectOption>
  get() = TypeContact.values().map { AdminSelectOption(it.ordinal, it.name) }
