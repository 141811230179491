package ca.sebleclerc.core.models

import ca.sebleclerc.core.LogLevel
import ca.sebleclerc.core.SharedLogger

class EventEditionDay(
  val id: Int,
  val idEventEdition: Int,
  var rawDayOfWeek: Int,
  var startTimeHour: Int,
  var startTimeMinute: Int,
  var endTimeHour: Int,
  var endTimeMinute: Int
) {

  companion object {}

  val startTime: Time
    get() = Time(startTimeHour, startTimeMinute)

  val endTime: Time
    get() = Time(endTimeHour, endTimeMinute)

  val dayOfWeek: DayOfWeek
    get() {
      DayOfWeek.all.forEach {
        if (it.dayIndex == rawDayOfWeek) {
          return it
        }
      }

      SharedLogger.log(LogLevel.ERROR, "Unknown rawDayOfWeek $rawDayOfWeek")
      SharedLogger.log(LogLevel.INFO, "Defaulting to Sunday")
      return DayOfWeek.Dimanche
    }
}
