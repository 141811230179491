package ca.sebleclerc.admin.components.ui

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div

@Composable
fun Row(content: @Composable() () -> Unit) { // ktlint-disable annotation
  Div({ classes("layout-flex-row") }) {
    content()
  }
}
