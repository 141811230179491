package ca.sebleclerc.admin

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import ca.sebleclerc.admin.di.initKoin
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.admin.repositories.VersionsRepositoryable

private val koin = initKoin().koin

@Composable
fun MainApp() {
  val placesRepo = koin.get<EtablissementsRepositoryable>()
  val versionsRepo = koin.get<VersionsRepositoryable>()
  val eventsRepo = koin.get<EventsRepositoryable>()

  LaunchedEffect(true) {
    placesRepo.loadData()
    versionsRepo.loadData()
    eventsRepo.loadData()
  }

  Router(koin)
}
