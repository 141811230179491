package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Event

class EditableEvent(var event: Event, isNew: Boolean = false) : EditableModel(isNew) {
  val nom = EditableField(event.nom) { event.nom = it }
  val nomAlternatif = EditableField(event.nomAlternatif) { event.nomAlternatif = it }
  val notes = EditableField(event.notes) { event.notes = it }

  init {
    fields = listOf(
      nom,
      nomAlternatif,
      notes
    )
  }

  val id: Int
    get() { return event.id }
}
