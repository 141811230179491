package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.core.models.Etablissement
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Tr

@Composable
fun EtablissementsListSingleView(etablissement: Etablissement) {
  Tr {
    Td { Text(etablissement.id.toString()) }
    Td { Text(etablissement.displayName) }
    Td {
      if (etablissement.statut == 0) {
        Icon(IconType.DELETED)
      }
    }
    Td {
      A("#/etablissements/${etablissement.id}") {
        Icon(IconType.EDIT)
      }
    }
  }
}
