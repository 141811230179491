package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Adresse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIAdresse(
  var id: Int,
  val nom: String?,
  @SerialName("num_civ")
  val numero_civique: String,
  val rue: String,
  val local: String?,
  val ville: String,
  val province: String,
  val pays: String,
  val code_postal: String,
  @SerialName("lat")
  val latitude: String,
  @SerialName("long")
  val longitude: String,
  val notes: String?,
  val statut: Int
)

fun APIAdresse.toDomain(): Adresse {
  return Adresse(
    id,
    nom,
    numero_civique,
    rue,
    local,
    ville,
    province,
    pays,
    code_postal,
    latitude,
    longitude,
    notes,
    statut
  )
}

fun Adresse.toAPI(): APIAdresse {
  return APIAdresse(
    id,
    nom,
    numCiv,
    rue,
    local,
    ville,
    province,
    pays,
    codePostal,
    latitude,
    longitude,
    notes,
    statut
  )
}
