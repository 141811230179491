package ca.sebleclerc.core

enum class Environment {
  Local {
    override val baseApiUrl = "http://localhost:8080"
    override val envName = "Local"
  },
  Beta {
    override val baseApiUrl = "https://sleclerc.opalstacked.com/api/v3"
    override val envName = "Beta"
  },
  Prod {
    override val baseApiUrl = "https://api.42bieres.ca/v3"
    override val envName = "Prod"
  };

  abstract val baseApiUrl: String
  abstract val envName: String
}
