package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.EtablissementAccess
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIEtablissementAccess(
  var id: Int,
  @SerialName("can_drink")
  val canDrink: Boolean?,
  @SerialName("can_eat")
  val canEat: Boolean?,
  @SerialName("has_boutique")
  val hasBoutique: Boolean?,
  @SerialName("dog_friendly")
  val dogFriendly: Boolean?
)

fun APIEtablissementAccess.toDomain(): EtablissementAccess {
  return EtablissementAccess(
    id,
    canDrink,
    canEat,
    hasBoutique,
    dogFriendly
  )
}

fun EtablissementAccess.toAPI(): APIEtablissementAccess {
  return APIEtablissementAccess(
    id,
    canDrink,
    canEat,
    hasBoutique,
    dogFriendly
  )
}
