package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.EventEdition
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class EditableEventEdition(
  var edition: EventEdition,
  isNew: Boolean = false
) : EditableModel(isNew) {
  val idEvent = EditableField(edition.idEvent) { edition.idEvent = it }
  val year = EditableField(edition.year) { edition.year = it }

  val startDateYear = EditableField(edition.startDateYear) { edition.startDateYear = it }
  val startDateMonth = EditableField(edition.startDateMonth) { edition.startDateMonth = it }
  val startDateDay = EditableField(edition.startDateDay) { edition.startDateDay = it }

  val endDateYear = EditableField(edition.endDateYear) { edition.endDateYear = it }
  val endDateMonth = EditableField(edition.endDateMonth) { edition.endDateMonth = it }
  val endDateDay = EditableField(edition.endDateDay) { edition.endDateDay = it }

  val informations = EditableField(edition.informations) { edition.informations = it }
  val statut = EditableField(edition.statut) { edition.statut = it }

  init {
    fields = listOf(
      idEvent,
      year,
      startDateYear,
      startDateMonth,
      startDateDay,
      endDateYear,
      endDateMonth,
      endDateDay,
      informations,
      statut
    )
  }

  val id: Int
    get() { return edition.id }

  companion object {
    fun create(eventId: Int): EditableEventEdition {
      val now = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())

      return EditableEventEdition(
        EventEdition(
          0,
          eventId,
          null,
          now.year,
          now.year,
          0,
          0,
          now.year,
          0,
          0,
          null,
          1
        ),
        isNew = true
      )
    }
  }
}
