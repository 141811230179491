package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import ca.sebleclerc.admin.components.ui.Column
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.SharedViewModel
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AdresseEditView(viewModel: SharedViewModel) {
  Column {
    viewModel.adresse?.also { adresse ->
      Row {
        Span { Text("Adresse") }
      }

      Row {
        AdminIDField(adresse.id)
        AdminNullTextField("Nom", adresse.nom)
      }

      Row {
        AdminTextField("Numéro civique", adresse.numCiv)
        AdminTextField("Rue", adresse.rue)
        AdminNullTextField("Local", adresse.local)
      }

      Row {
        AdminTextField("Ville", adresse.ville)
        AdminTextField("Province", adresse.province)
        AdminTextField("Pays", adresse.pays)
      }

      Row {
        AdminTextField("Code Postal", adresse.codePostal)
        AdminTextField("Latitude", adresse.latitude)
        AdminTextField("Longitude", adresse.longitude)
      }

      Row {
        AdminNullTextField("Notes", adresse.notes)
      }

      Row {
        Button("Get Lat/Long") {
          viewModel.getLatLong()
        }

        Button("Validate") {
          viewModel.validateLatLong()
        }
      }
    }
  }
}
