package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul

@Composable
fun MainScreen() {
  H1 { Text("Console d'administration") }
  H2 { Text("Listes") }
  Ul {
    Li {
      A(href = "#/etablissements") {
        Text("Établissements")
      }
    }
    Li {
      A(href = "#/events") {
        Text("Événements")
      }
    }
    Li {
      A(href = "#/versions") {
        Text("Versions")
      }
    }
    Li {
      A(href = "#/validations") {
        Text("Validations")
      }
    }
  }

  H2 { Text("Création") }
  Ul {
    Li {
      A(href = "#/etablissements/new") {
        Text("New => Etablissement")
      }
    }
    Li {
      A(href = "#/events/new") {
        Text("New => Event")
      }
    }
    Li {
      A(href = "#/versions/new") {
        Text("New => Version")
      }
    }
  }
}
