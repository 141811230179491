package ca.sebleclerc.core.models

import kotlinx.serialization.Serializable

@Serializable
data class Contact(
  var id: Int,
  var adresse: String,
  var idExterne: String? = null,
  var displayLabel: String? = null,
  var idTypeContact: Int,
  var actif: Int
) {

  companion object {}

  val typeContact: TypeContact
    get() {
      return TypeContact.Companion.valueOf(idTypeContact) ?: TypeContact.SiteWeb
    }

  val stringValue: String
    get() {
      if (displayLabel != null) {
        return displayLabel!!
      } else {
        if (typeContact == TypeContact.NumTelephone) {
          val first = adresse.substring(0, 3)
          val second = adresse.substring(3, 6)
          val last = adresse.substring(6)

          return "+1 ($first) $second-$last"
        }

        return adresse
      }
    }
}
