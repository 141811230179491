package ca.sebleclerc.admin.screens.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import ca.sebleclerc.admin.components.AdminIDField
import ca.sebleclerc.admin.components.AdminNullTextField
import ca.sebleclerc.admin.components.AdminTextField
import ca.sebleclerc.admin.components.AdresseEditView
import ca.sebleclerc.admin.components.ContactEditView
import ca.sebleclerc.admin.components.ContactsEditSection
import ca.sebleclerc.admin.components.EventEditionDisplay
import ca.sebleclerc.admin.components.ImageEditView
import ca.sebleclerc.admin.components.ImagesEditSection
import ca.sebleclerc.admin.components.SubtitleView
import ca.sebleclerc.admin.components.TitleView
import ca.sebleclerc.admin.components.ui.FlexContainer
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.EventEditViewModel
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun EventEditView(viewModel: EventEditViewModel) {
  Div({ style { height(20.px) } })

  FlexContainer {
    TitleView("Événement")

    Row {
      AdminIDField(viewModel.event.id)
      AdminTextField("Nom", viewModel.event.nom)
      AdminNullTextField("Nom Alt", viewModel.event.nomAlternatif)
    }

    Row {
      AdminNullTextField("Notes", viewModel.event.notes)
    }

    SubtitleView("Adresse")

    Row {
      if (viewModel.adresse != null) {
        AdresseEditView(viewModel)
      } else {
        Button("Add adresse") {
          viewModel.addAddress()
        }
      }
    }

    SubtitleView("Editions")

    Row {
      Button("Add") {
        viewModel.addEdition()
      }
    }

    viewModel
      .editions
      .sortedBy { it.year.value }
      .forEach {
        Row {
          Div({ style { width(20.px) } })
          EventEditionDisplay(it)
        }
      }

    ContactsEditSection(viewModel)

    ImagesEditSection(viewModel)

    Row {
      Button("Save") {
         GlobalScope.launch { viewModel.createOrUpdate() }
      }
    }
  }
}
