package ca.sebleclerc.admin.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.models.EditableAdresse
import ca.sebleclerc.admin.models.EditableContact
import ca.sebleclerc.admin.models.EditableImage
import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.admin.repositories.AdressesRepositoryable
import ca.sebleclerc.admin.repositories.ContactsRepositoryable
import ca.sebleclerc.admin.repositories.ImagesRepositoryable
import ca.sebleclerc.core.SharedLogger
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

open class SharedViewModel(private val reload: () -> Unit) : KoinComponent {
  val contactRepo: ContactsRepositoryable by inject()
  private val addrRepo: AdressesRepositoryable by inject()
  private val imageRepo: ImagesRepositoryable by inject()

  var adresse by mutableStateOf<EditableAdresse?>(null)
  var contacts = mutableStateListOf<EditableContact>()
  var showContactModal by mutableStateOf(false)
  var modalContactIds by mutableStateOf("")
  var images = mutableStateListOf<EditableImage>()
  var showImageModal by mutableStateOf(false)
  var modalImageIds by mutableStateOf("")

  // Adresses

  fun addAddress() {
    adresse = EditableAdresse.createNew()
  }

  fun getLatLong() {
    adresse?.let {
      GlobalScope.launch {
        val latLong = addrRepo.getLatLong(it.adresse)

        it.latitude.setValue(latLong.first)
        it.longitude.setValue(latLong.second)
      }
    }
  }

  fun validateLatLong() {
    SharedLogger.debug("getValidationUrl")
    val url = "https://maps.google.com?q=${adresse?.latitude?.value},${adresse?.longitude?.value}"
    window.open(url)
  }

  // Contacts

  fun didClickAddContact() {
    contacts.add(EditableContact.createNew())
  }

  fun didClickAssignContact() {
    SharedLogger.debug("didClickAssignModal")
    modalContactIds = ""
    showContactModal = true
  }

  open fun didFinishAssignContact() {
    SharedLogger.warning("Should be overridden.")
  }

  protected fun assignContact(relation: SharedRelation) {
    showContactModal = false

    GlobalScope.launch {
      val success = contactRepo.assignContact(relation, modalContactIds)

      if (success) {
        showSuccessConfirmation(relation, false)
        reload()
      } else {
        showFailureConfirmation(false)
      }
    }
  }

  protected suspend fun updateContacts(relation: SharedRelation): List<Boolean?> {
    SharedLogger.debug("updateContacts")
    val responses = mutableListOf<Boolean?>()

    contacts.forEach {
      if (it.shouldBeUpdated) {
        SharedLogger.debug("Create/Update contact with ID ${it.id}")
        val success = contactRepo.createOrUpdateContact(relation, it.contact)
        responses.add(success)
      } else {
        responses.add(null)
      }
    }

    SharedLogger.debug("All contacts updated with success? ${responses.joinToString(" - ")}")
    return responses
  }

  // Images

  fun didClickAddImage() {
    images.add(EditableImage.createNew())
  }

  fun didClickAssignImage() {
    modalImageIds = ""
    showImageModal = true
  }

  open fun didFinishAssignImage() {
    SharedLogger.warning("Should be overridden.")
  }

  protected fun assignImage(relation: SharedRelation) {
    showImageModal = false

    GlobalScope.launch {
      val success = imageRepo.assignImage(relation, modalImageIds)

      if (success) {
        showSuccessConfirmation(relation, false)
        reload()
      } else {
        showFailureConfirmation(false)
      }
    }
  }

  protected suspend fun updateImages(relation: SharedRelation): List<Boolean?> {
    SharedLogger.debug("updateImages")
    val responses = mutableListOf<Boolean?>()

    images.forEach {
      if (it.shouldBeUpdated) {
        SharedLogger.debug("Create/Update image with ID ${it.id}")
        val success = imageRepo.createOrUpdateImage(relation, it.image)
        responses.add(success)
      } else {
        responses.add(null)
      }
    }

    SharedLogger.debug("All images updated success? ${responses.joinToString(" - ")}")
    return responses
  }

  // UI confirmation / error

  protected fun finalizeCreateOrUpdate(relation: SharedRelation, responses: List<Boolean?>, creating: Boolean) {
    val finalResponses = responses.filterNotNull()

    if (finalResponses.isEmpty()) {
      window.alert("Nothing to save.")
    } else if (false in finalResponses) {
      showFailureConfirmation(creating)
    } else {
      showSuccessConfirmation(relation, creating)
    }
  }

  private fun showSuccessConfirmation(relation: SharedRelation, creating: Boolean) {
    window.alert("Save/Update successful")

    if (creating) {
      val location: String = when (relation) {
        is SharedRelation.Etablissement -> {
          "#/etablissements/${relation.id}"
        }

        is SharedRelation.Event -> {
          "#/events/${relation.id}"
        }

        is SharedRelation.EventEdition -> {
          "#/events/${relation.eventId}/editions/${relation.editionId}"
        }
      }

      window.location.href = location
    } else {
      reload()
    }
  }

  protected fun showFailureConfirmation(creating: Boolean) {
    var message = "Erreur lors de la "

    message += if (creating) "création" else "mise à jour"

    SharedLogger.error(message)
    window.alert(message)
  }
}
