package ca.sebleclerc.core.models

data class Version(
  val id: Int,
  var major: Int,
  var minor: Int,
  var build: Int,
  var scriptName: String
) {
  companion object {}

  val stringValue: String
    get() = "v$major.$minor.$build"

  /**
   * Compare current version with another
   * @param other The other version to compare
   * @return -1 if other is before,
   *          0 if they are equals,
   *          1 if it's after
   */
  fun compareTo(other: Version): Int {
    val before = -1
    val same = 0
    val after = 1

    if (major > other.major) {
      return before
    } else if (major < other.major) {
      return after
    } else {
      if (minor > other.minor) {
        return before
      } else if (minor < other.minor) {
        return after
      } else {
        if (build > other.build) {
          return before
        } else if (build < other.build) {
          return after
        }
      }
    }

    return same
  }
}
