package ca.sebleclerc.network.models

import kotlinx.serialization.Serializable

@Serializable
data class LatLongResponse(
  val status: String,
  val results: List<LatLongResponseResult>
)

@Serializable
data class LatLongResponseResult(
  val geometry: LatLongResponseGeometry
)

@Serializable
data class LatLongResponseGeometry(
  val location: LatLongResponseGeometryLocation
)

@Serializable
data class LatLongResponseGeometryLocation(
  val lat: Double,
  val lng: Double
)
