package ca.sebleclerc.core.models

data class Image(
  val id: Int,
  var imageName: String,
  var idType: Int,
  var actif: Int
) {
  companion object {
    const val baseImageUrl = "https://images.42bieres.ca/"
  }

  val type: ImageType
    get() {
      return ImageType.valueOf(idType)
    }
}
