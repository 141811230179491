package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.EtablissementAccess

class EditableAccess(
  val access: EtablissementAccess,
  isNew: Boolean = false
) : EditableModel(isNew) {
  val canDrink = EditableField(access.canDrink) { access.canDrink = it }
  val canEat = EditableField(access.canEat) { access.canEat = it }
  val hasBoutique = EditableField(access.hasBoutique) { access.hasBoutique = it }
  val dogFriendly = EditableField(access.dogFriendly) { access.dogFriendly = it }

  init {
    fields = listOf(
      canDrink,
      canEat,
      hasBoutique,
      dogFriendly
    )
  }

  val id: Int
    get() { return access.id }

  companion object {
    fun createNew(): EditableAccess {
      return EditableAccess(
        EtablissementAccess(
          0,
          null,
          null,
          null,
          null
        ),
        isNew = true
      )
    }
  }
}
