package ca.sebleclerc.network.models

object APIConstants {
  const val headerToken = "X-Access-Token"
  const val headerMajor = "X-Major-Version"
  const val headerMinor = "X-Minor-Version"
  const val headerBuild = "X-Build-Version"

  const val headerContentType = "Content-Type"
  const val headerTypeJson = "application/json"
}
