package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.editables.EditableField
import dev.petuska.kmdc.select.MDCSelect
import dev.petuska.kmdc.select.anchor.Anchor
import dev.petuska.kmdc.select.menu.Menu
import dev.petuska.kmdc.select.menu.SelectItem
import dev.petuska.kmdc.select.onChange

data class AdminSelectOption(val value: Int, val title: String)

@Composable
fun AdminSelect(title: String, field: EditableField<Int>, options: List<AdminSelectOption>) {
  SharedAdminSelect(
    title = title,
    selectedValue = field.value,
    options = options
  ) {
    field.setValue(it)
  }
}

@Composable
fun AdminSelect(title: String, field: EditableField<Boolean?>, options: List<AdminSelectOption>) {
  SharedAdminSelect(
    title = title,
    options = options,
    selectedValue = field.value?.toSelectValue() ?: -1
  ) {
    field.setValue(it.toOptionalBoolean())
  }
}

@Composable
private fun SharedAdminSelect(
  title: String,
  selectedValue: Int,
  options: List<AdminSelectOption>,
  onSelectedChange: (Int) -> Unit
) {
  MDCSelect(
    attrs = {
      onChange { onSelectedChange(options[it.detail.index].value) }
    }
  ) {
    Anchor(label = title)
    Menu {
      options.forEach {
        SelectItem(
          text = it.title,
          selected = selectedValue == it.value,
          attrs = {
            attr("data-value", it.value.toString())
          }
        )
      }
    }
  }
}

private fun Int.toOptionalBoolean(): Boolean? {
  return when (this) {
    0 -> false
    1 -> true
    else -> null
  }
}

private fun Boolean?.toSelectValue(): Int {
  return if (this != null) {
    if (this) 1 else 0
  } else {
    -1
  }
}
