package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.EventEditionDay
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIEventEditionDay(
  val id: Int,
  @SerialName("id_event_edition")
  val idEventEdition: Int,
  @SerialName("day_of_week")
  val dayOfWeek: Int,
  @SerialName("start_time_hour")
  val startTimeHour: Int,
  @SerialName("start_time_minute")
  val startTimeMinute: Int,
  @SerialName("end_time_hour")
  val endTimeHour: Int,
  @SerialName("end_time_minute")
  val endTimeMinute: Int
)

fun APIEventEditionDay.toDomain(): EventEditionDay {
  return EventEditionDay(
    id,
    idEventEdition,
    dayOfWeek,
    startTimeHour,
    startTimeMinute,
    endTimeHour,
    endTimeMinute
  )
}

fun EventEditionDay.toAPI(): APIEventEditionDay {
  return APIEventEditionDay(
    id,
    idEventEdition,
    rawDayOfWeek,
    startTimeHour,
    startTimeMinute,
    endTimeHour,
    endTimeMinute
  )
}
