package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Contact
import kotlinx.serialization.Serializable

@Serializable
data class APIContact(
  val id: Int,
  val adresse: String,
  var id_externe: String?,
  var display_label: String?,
  val id_type_contact: Int,
  val actif: Int
)

fun APIContact.toDomain(): Contact {
  return Contact(
    id,
    adresse,
    id_externe,
    display_label,
    id_type_contact,
    actif
  )
}

fun Contact.toAPI(): APIContact {
  return APIContact(
    id,
    adresse,
    idExterne,
    displayLabel,
    idTypeContact,
    actif
  )
}
