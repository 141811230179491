package ca.sebleclerc.admin.screens.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import ca.sebleclerc.admin.components.AccessEditView
import ca.sebleclerc.admin.components.AdminIDField
import ca.sebleclerc.admin.components.AdminNullNumberField
import ca.sebleclerc.admin.components.AdminNullTextField
import ca.sebleclerc.admin.components.AdminSelect
import ca.sebleclerc.admin.components.AdminSelectOption
import ca.sebleclerc.admin.components.AdminTextField
import ca.sebleclerc.admin.components.AdresseEditView
import ca.sebleclerc.admin.components.ContactsEditSection
import ca.sebleclerc.admin.components.ImagesEditSection
import ca.sebleclerc.admin.components.SubtitleView
import ca.sebleclerc.admin.components.TitleView
import ca.sebleclerc.admin.components.ui.FlexContainer
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.EtablissementEditViewModel
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun EtablissementEditView(viewModel: EtablissementEditViewModel) {
  Div({ style { height(20.px) } })

  FlexContainer {
    TitleView("Etablissement")

    viewModel.place.idGroup?.value?.let {
      Row {
        A(href = "#/etablissements/groups/${it}") {
          Text("Back to Group")
        }
      }
    }

    Row {
      AdminIDField(viewModel.place.id)
      AdminSelect("Statut", viewModel.place.statut, actifValues)
      AdminSelect("Type", viewModel.place.idTypeEtablissement, typeEtablissements)
    }

    Row {
      AdminTextField("Nom", viewModel.place.nom)
      AdminNullTextField("Nom alternatif", viewModel.place.nomAlternatif)
      AdminNullNumberField("ID Group", viewModel.place.idGroup)
    }

    Row {
      AdminNullTextField("Notes", viewModel.place.notes)
    }

    SubtitleView("Adresse")

    Row {
      if (viewModel.adresse != null) {
        AdresseEditView(viewModel)
      } else {
        Button("Add adresse") {
          viewModel.addAddress()
        }
      }
    }

    SubtitleView("Access")

    Row {
      if (viewModel.access != null) {
        AccessEditView(viewModel.access!!)
      } else {
        Button("Add access") {
          viewModel.addAccess()
        }
      }
    }

    ContactsEditSection(viewModel)

    ImagesEditSection(viewModel)

    Row {
      Button("Save") {
        GlobalScope.launch { viewModel.createOrUpdate() }
      }
    }
  }
}

private val actifValues: List<AdminSelectOption>
  get() = listOf(
    AdminSelectOption(0, "Inactif"),
    AdminSelectOption(1, "Actif")
  )

private val typeEtablissements: List<AdminSelectOption>
  get() = listOf(
    AdminSelectOption(0, "Inconnu"),
    AdminSelectOption(10, "Microbrasserie"),
    AdminSelectOption(15, "Salon de dégustation"),
    AdminSelectOption(20, "Bistrot-Brasserie"),
    AdminSelectOption(30, "Détaillant"),
    AdminSelectOption(31, "Détaillant specialisé"),
    AdminSelectOption(32, "Épicerie"),
    AdminSelectOption(33, "Dépanneur"),
    AdminSelectOption(40, "Restaurant/Bar"),
    AdminSelectOption(41, "Restaurant"),
    AdminSelectOption(42, "Bar"),
    AdminSelectOption(43, "Pub")
  )
