package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.components.LoadingView
import ca.sebleclerc.admin.models.LoadingState
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.admin.screens.views.EventEditView
import ca.sebleclerc.admin.viewmodels.EventEditViewModel
import ca.sebleclerc.core.models.Event
import ca.sebleclerc.core.models.EventEdition
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin
import org.koin.core.component.KoinComponent

@Composable
fun EventEditScreen(koin: Koin, eventId: Int) {
  var state by remember { mutableStateOf(LoadingState.LOADING) }
  var event by remember { mutableStateOf<Event?>(null) }
  var editions = remember { mutableStateListOf<EventEdition>() }
  val repo = koin.get<EventsRepositoryable>()

  val scope = rememberCoroutineScope()
  val reloadClosure: () -> Unit = {
    scope.launch {
      state = LoadingState.LOADING

      val repoEvent = repo.getEventWithID(eventId)
      event = repoEvent

      val repoEditions = repo.getEventEditions(eventId)
      editions.clear()
      editions.addAll(repoEditions)

      state = if (event != null) LoadingState.VALUE else LoadingState.ERROR
    }
  }

  LaunchedEffect(true) {
    reloadClosure()
  }

  when (state) {
    LoadingState.LOADING -> {
      LoadingView()
    }
    LoadingState.ERROR -> {
      P { Text("Oops! Erreur!") }
    }
    LoadingState.VALUE -> {
      val viewModel = EventEditViewModel(event!!, editions, reloadClosure)
      EventEditView(viewModel)
    }
  }
}
