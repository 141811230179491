package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Contact
import ca.sebleclerc.core.models.TypeContact

class EditableContact(var contact: Contact, isNew: Boolean = false) : EditableModel(isNew) {
  val adresse = EditableField(contact.adresse) { contact.adresse = it }
  val idTypeContact = EditableField(contact.idTypeContact) { contact.idTypeContact = it }
  val actif = EditableField(contact.actif) { contact.actif = it }
  val idExterne = EditableField(contact.idExterne) { contact.idExterne = it }
  val displayLabel = EditableField(contact.displayLabel) { contact.displayLabel = it }

  init {
    fields = listOf(
      adresse,
      idTypeContact,
      actif,
      idExterne,
      displayLabel
    )
  }

  val id: Int
    get() {
      return contact.id
    }

  val typeContact: TypeContact
    get() {
      return contact.typeContact
    }

  companion object {
    fun createNew(): EditableContact {
      return EditableContact(
        Contact(
          0,
          "",
          null,
          null,
          0,
          1
        ),
        isNew = true
      )
    }
  }
}
