package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.components.ui.Row
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

@Composable
fun TitleView(text: String) {
  Row {
    H1 {
      Text(text)
    }
  }
}
