package ca.sebleclerc.core.models

class Event(
  var id: Int,
  var nom: String,
  var nomAlternatif: String?,
  var adresse: Adresse?,
  var notes: String?,
  var contacts: List<Contact>,
  var images: List<Image>
) {
  companion object {}

  val displayName: String
    get() = nomAlternatif ?: nom
}
