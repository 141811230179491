package ca.sebleclerc.admin.models.editables

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import ca.sebleclerc.core.SharedLogger

class EditableField<T>(
  private val originalValue: T,
  private val apply: (T) -> Unit
) {
  var value by mutableStateOf(originalValue)
    private set

  val hasChanged: Boolean
    get() { return originalValue != value }

  fun setValue(v: T) {
    value = v
    apply(v)
  }

  fun reset() {
    value = originalValue
    apply(originalValue)
  }
}
