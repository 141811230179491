package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableEventEdition
import ca.sebleclerc.admin.screens.views.EventEditionEditView
import ca.sebleclerc.admin.viewmodels.EventEditionEditViewModel

@Composable
fun EventEditionCreateScreen(eventId: Int) {
  val viewModel = EventEditionEditViewModel(
    eventId,
    EditableEventEdition.create(eventId).edition,
    emptyList(),
    {}
  )

  EventEditionEditView(viewModel)
}