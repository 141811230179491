package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.components.ui.Column
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.models.EditableAccess
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AccessEditView(access: EditableAccess) {
  Column {
    Row {
      Span { Text("Access") }
    }

    Row {
      AdminSelect(
        "Can drink?",
        access.canDrink,
        accessValues
      )
    }

    Row {
      AdminSelect(
        "Can eat?",
        access.canEat,
        accessValues
      )
    }

    Row {
      AdminSelect(
        "Has boutique?",
        access.hasBoutique,
        accessValues
      )
    }

    Row {
      AdminSelect(
        "Dog friendly?",
        access.dogFriendly,
        accessValues
      )
    }
  }
}

private val accessValues: List<AdminSelectOption>
  get() = listOf(
    AdminSelectOption(-1, "Unknown"),
    AdminSelectOption(1, "Can"),
    AdminSelectOption(0, "Disabled")
  )
