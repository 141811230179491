package ca.sebleclerc.core.models

enum class TypeContact(val value: Int) {
  SiteWeb(0),
  Courriel(1),
  Facebook(2),
  Twitter(3),
  Instagram(4),
  GooglePlus(5),
  NumTelephone(6),
  Tumblr(7),
  Youtube(8);

  companion object {

    private val map = TypeContact.values().associateBy(TypeContact::value)

    fun valueOf(value: Int): TypeContact? {
      return map[value]
    }
  }
}
