package ca.sebleclerc.network.models

import kotlinx.serialization.Serializable

@Serializable
data class APIValidation(
  val id: String,
  val title: String,
  val errors: String
)
