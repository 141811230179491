package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Image
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIImage(
  val id: Int,
  val imageName: String,
  @SerialName("id_type")
  val idType: Int,
  val actif: Int
)

fun APIImage.toDomain(): Image {
  return Image(
    id,
    imageName,
    idType,
    actif
  )
}

fun Image.toAPI(): APIImage {
  return APIImage(
    id,
    imageName,
    idType,
    actif
  )
}
