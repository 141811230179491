package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableEventEditionDay

@Composable
fun EventEditionDayEditView(day: EditableEventEditionDay) {
  AdminIDField(day.id)
  AdminSelect("Day", day.rawDayOfWeek, dayOfWeekValues)
  AdminNumberField("StartHour", day.startTimeHour)
  AdminNumberField("StartMin", day.startTimeMinute)
  AdminNumberField("EndHour", day.endTimeHour)
  AdminNumberField("EndMin", day.endTimeMinute)
}
