package ca.sebleclerc.admin.repositories

import ca.sebleclerc.core.models.Adresse
import ca.sebleclerc.network.IAPIService

interface AdressesRepositoryable {
  suspend fun getLatLong(adresse: Adresse): Pair<String, String>
}

class AdressesRepository(private val apiService: IAPIService) : AdressesRepositoryable {
  override suspend fun getLatLong(adresse: Adresse): Pair<String, String> {
    return apiService.getLatLong(adresse)
  }
}
