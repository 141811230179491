package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.components.LoadingView
import ca.sebleclerc.admin.models.LoadingState
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.admin.screens.views.EventEditionEditView
import ca.sebleclerc.admin.viewmodels.EventEditionEditViewModel
import ca.sebleclerc.core.models.EventEdition
import ca.sebleclerc.core.models.EventEditionDay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin

@Composable
fun EventEditionEditScreen(koin: Koin, eventId: Int, editionId: Int) {
  val repo = koin.get<EventsRepositoryable>()

  var state by remember { mutableStateOf(LoadingState.LOADING) }
  var edition by remember { mutableStateOf<EventEdition?>(null) }
  var days = remember { mutableStateListOf<EventEditionDay>() }

  val scope = rememberCoroutineScope()
  val reloadClosure: () -> Unit = {
    scope.launch {
      state = LoadingState.LOADING

      val repoEdition = repo.getSingleEventEdition(eventId, editionId)
      edition = repoEdition

      val repoDays = repo.getEventEditionDays(eventId, editionId)
      days.clear()
      days.addAll(repoDays)

      state = if (edition != null) LoadingState.VALUE else LoadingState.ERROR
    }
  }

  LaunchedEffect(true) {
    reloadClosure()
  }

  when (state) {
    LoadingState.LOADING -> {
      LoadingView()
    }
    LoadingState.ERROR -> {
      P { Text("Oops! Erreur!") }
    }
    LoadingState.VALUE -> {
      val viewModel = EventEditionEditViewModel(eventId, edition!!, days, reloadClosure)
      EventEditionEditView(viewModel)
    }
  }
}
