package ca.sebleclerc.core.models

enum class DayOfWeek(private val _dayIndex: Int) {
  Lundi(0),
  Mardi(1),
  Mercredi(2),
  Jeudi(3),
  Vendredi(4),
  Samedi(5),
  Dimanche(6);

  val dayIndex: Int
    get() = _dayIndex

  val isWeekend: Boolean
    get() = DayOfWeek.weekends.map { dayIndex }.contains(dayIndex)

  companion object {
    val weekDays: List<DayOfWeek>
      get() = listOf(Lundi, Mardi, Mercredi, Jeudi, Vendredi)

    val weekends: List<DayOfWeek>
      get() = listOf(Samedi, Dimanche)

    val all: List<DayOfWeek>
      get() = listOf(Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi, Dimanche)
  }
}
