package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.admin.components.EtablissementsListSingleView
import ca.sebleclerc.admin.components.Icon
import ca.sebleclerc.admin.components.IconType
import ca.sebleclerc.admin.components.ui.Column
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Tr
import org.koin.core.Koin

@Composable
fun EtablissementsListScreen(koin: Koin) {
  val repo = koin.get<EtablissementsRepositoryable>()
  val places = repo.etablissements.collectAsState()

  val singles = places.value.filter { it.key == null }
  val grouped = places.value.filter { it.key != null }

  H1 { Text("Établissements") }

  Row {
    Column {
      Table {
        EtablissementsListScreenHeader()

        singles[null]?.forEach {
          EtablissementsListSingleView(it)
        }
      }
    }

    Column {
      Table {
        EtablissementsListScreenHeader()
        grouped.forEach { entry ->
          val first = entry.value.first()

          Tr {
            Td { }
            Td { H5 { Text("${first.idGroup ?: -1} - ${first.nom}") } }
            Td {
              A("#/etablissements/groups/${first.idGroup ?: -1}") {
                Icon(IconType.EDIT)
              }
            }
          }

          entry.value.forEach {
            EtablissementsListSingleView(it)
          }
        }
      }
    }
  }
}

@Composable
private fun EtablissementsListScreenHeader() {
  Tr {
    Th { Text("ID") }
    Th { Text("Nom") }
    Th { Text("Inactif?") }
    Th { Text("Actions") }
  }
}
