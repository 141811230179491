package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Image
import ca.sebleclerc.core.models.ImageType

class EditableImage(var image: Image, isNew: Boolean = false) : EditableModel(isNew) {
  val imageName = EditableField(image.imageName) { image.imageName = it }
  val idType = EditableField(image.idType) { image.idType = it }
  val actif = EditableField(image.actif) { image.actif = it }

  init {
    fields = listOf(
      imageName,
      idType,
      actif
    )
  }

  val id: Int
    get() {
      return image.id
    }

  val type: ImageType
    get() {
      return image.type
    }

  companion object {
    fun createNew(): EditableImage {
      return EditableImage(
        Image(
          0,
          "",
          0,
          1
        ),
        isNew = true
      )
    }
  }
}
