package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.EventEdition
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIEventEdition(
  val id: Int,
  @SerialName("id_event")
  val idEvent: Int,
  val adresse: APIAdresse? = null,
  val year: Int,
  val start_date_year: Int,
  val start_date_month: Int,
  val start_date_day: Int,
  val end_date_year: Int,
  val end_date_month: Int,
  val end_date_day: Int,
  val informations: String? = null,
  val statut: Int
)

fun APIEventEdition.toDomain(): EventEdition {
  return EventEdition(
    id,
    idEvent,
    adresse?.toDomain(),
    year,
    start_date_year,
    start_date_month,
    start_date_day,
    end_date_year,
    end_date_month,
    end_date_day,
    informations,
    statut
  )
}

fun EventEdition.toAPI(): APIEventEdition {
  return APIEventEdition(
    id,
    idEvent,
    adresse?.toAPI(),
    year,
    startDateYear,
    startDateMonth,
    startDateDay,
    endDateYear,
    endDateMonth,
    endDateDay,
    informations,
    statut
  )
}
