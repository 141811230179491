package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.createNew
import ca.sebleclerc.admin.screens.views.EventEditView
import ca.sebleclerc.admin.viewmodels.EventEditViewModel
import ca.sebleclerc.core.models.Event

@Composable
fun EventCreateScreen() {
  val viewModel = EventEditViewModel(Event.createNew(), emptyList(), {})
  EventEditView(viewModel)
}
