package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Tr
import org.koin.core.Koin

@Composable
fun EventsListScreen(koin: Koin) {
  val repo = koin.get<EventsRepositoryable>()
  val events = repo.events.collectAsState()
  val editions = repo.editions.collectAsState()

  H1 { Text("Événements") }

  Table {
    Tr {
      Th { Text("ID") }
      Th { Text("Nom") }
      Th { Text("Editions") }
      Th { Text("Actions") }
    }

    events.value.forEachIndexed { _, event ->
      Tr {
        Td { Text(event.id.toString()) }
        Td { Text(event.displayName) }
        Td { Text(editions.value.filter { it.idEvent == event.id }.map { it.year }.joinToString(", ")) }
        Td {
          A("#/events/${event.id}") {
            Span({ classes("material-icons") }) {
              Text("mode_edit")
            }
          }
        }
      }
    }
  }
}
