package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableImage
import ca.sebleclerc.core.models.Image
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun ImageEditView(image: EditableImage) {
  val imagePath = "${Image.baseImageUrl}${image.imageName.value.replace(".jpg", "-thumb.jpg")}"

  AdminIDField(image.id)
  AdminTextField("ImageName", image.imageName)
  AdminSelect("Type", image.idType, imageTypeValues)
  AdminSelect("Actif", image.actif, actifSelectValues)
  Img(src = imagePath, attrs = { style { width(165.px); height(165.px) } })
  A(href = imagePath, { target(ATarget.Blank) }) {
    Span({ classes("material-icons") }) {
      Text("open_in_new")
    }
  }
}
