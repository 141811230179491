package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.viewmodels.HeaderViewModel
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin

@Composable
fun Header(koin: Koin) {
  val viewModel = koin.get<HeaderViewModel>()

  Div({ classes("topnav") }) {
    A(href = "#/", { classes("active") }) {
      Text("Home")
    }
    A(href = "#/etablissements") {
      Text("Établissements")
    }
    A(href = "#/events") {
      Text("Événements")
    }
    A(href = "#/versions") {
      Text("Versions")
    }
    A(href = "#/validations") {
      Text("Validations")
    }
    Span {
      Text("(${viewModel.environment.envName})")
    }
  }
}
