package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Version
import kotlinx.serialization.Serializable

@Serializable
data class APIVersion(
  val id: Int,
  val major_version: Int,
  val minor_version: Int,
  val build_version: Int,
  val script_name: String
)

fun APIVersion.toDomain(): Version {
  return Version(
    id,
    major_version,
    minor_version,
    build_version,
    script_name
  )
}

fun Version.toAPI(): APIVersion {
  return APIVersion(
    id,
    major,
    minor,
    build,
    scriptName
  )
}
