package ca.sebleclerc.core

enum class LogLevel {
  ERROR,
  WARNING,
  DEBUG,
  INFO
}

class SharedLogger {
  companion object default {
    fun log(level: LogLevel = LogLevel.ERROR, message: String) {
      logMessage(level, message)
    }

    fun info(message: String) {
      log(LogLevel.INFO, message)
    }

    fun debug(message: String) {
      log(LogLevel.DEBUG, message)
    }

    fun warning(message: String) {
      log(LogLevel.WARNING, message)
    }

    fun error(message: String) {
      log(LogLevel.ERROR, message)
    }
  }
}

internal expect fun logMessage(level: LogLevel, message: String)
