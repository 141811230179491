package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Modal
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.SharedViewModel
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun ImagesEditSection(viewModel: SharedViewModel) {
  SubtitleView("Images")

  Row {
    Button("Add Image") {
      viewModel.didClickAddImage()
    }

    Button("Assign Image") {
      viewModel.didClickAssignImage()
    }
  }

  viewModel.images.forEach { image ->
    Row {
      ImageEditView(image)
    }
  }

  if (viewModel.showImageModal) {
    Modal(
      "Assign images",
      onDismissRequest = { viewModel.showImageModal = false },
      footer = {
        Button("Assign") { viewModel.didFinishAssignImage() }
        Button("Close") { viewModel.showImageModal = false }
      }
    ) {
      Span({ style { paddingRight(20.px) } }) { Text("Image IDs:") }
      TextInput(value = viewModel.modalImageIds, attrs = {
        onInput {
          viewModel.modalImageIds = it.value
        }
      })
    }
  }
}