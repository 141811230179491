package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.Adresse

class EditableAdresse(val adresse: Adresse, isNew: Boolean = false) : EditableModel(isNew) {
  val nom = EditableField(adresse.nom) { adresse.nom = it }
  val numCiv = EditableField(adresse.numCiv) { adresse.numCiv = it }
  val rue = EditableField(adresse.rue) { adresse.rue = it }
  val local = EditableField(adresse.local) { adresse.local = it }
  val ville = EditableField(adresse.ville) { adresse.ville = it }
  val province = EditableField(adresse.province) { adresse.province = it }
  val pays = EditableField(adresse.pays) { adresse.pays = it }
  val codePostal = EditableField(adresse.codePostal) { adresse.codePostal = it }
  val latitude = EditableField(adresse.latitude) { adresse.latitude = it }
  val longitude = EditableField(adresse.longitude) { adresse.longitude = it }
  val notes = EditableField(adresse.notes) { adresse.notes = it }
  val statut = EditableField(adresse.statut) { adresse.statut = it }

  init {
    fields = listOf(
      nom,
      numCiv,
      rue,
      local,
      ville,
      province,
      pays,
      codePostal,
      latitude,
      longitude,
      notes,
      statut
    )
  }

  val id: Int
    get() { return adresse.id }

  companion object {
    fun createNew(): EditableAdresse {
      return EditableAdresse(
        Adresse(
          0,
          null,
          "",
          "",
          null,
          "",
          "Québec",
          "Canada",
          "",
          "",
          "",
          null,
          1
        ),
        isNew = true
      )
    }
  }
}
