package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun LoadingView() {
  Div({ classes("center") }) {
    Div({ classes("loader") })
    P { Text("Loading...") }
  }
}
