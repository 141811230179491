package ca.sebleclerc.admin.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.models.EditableEventEdition
import ca.sebleclerc.admin.models.EditableEventEditionDay
import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.EventEdition
import ca.sebleclerc.core.models.EventEditionDay
import ca.sebleclerc.network.models.CreateOrUpdateResult
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class EventEditionEditViewModel(private val eventId: Int, edition: EventEdition, edDays: List<EventEditionDay>, reload: () -> Unit) : SharedViewModel(reload), KoinComponent {
  private val repo: EventsRepositoryable by inject()

  var edition by mutableStateOf(EditableEventEdition(edition))
  var days = mutableStateListOf<EditableEventEditionDay>()

  init {
    days.addAll(edDays.map { EditableEventEditionDay(it) })
  }

  // Days

  fun addDay() {
    days.add(EditableEventEditionDay.createNew())
  }

  // Save

  suspend fun createOrUpdate() {
    SharedLogger.debug("createOrUpdate")
    var responses = mutableListOf<Boolean?>()
    var creating = false

    if (shouldUpdateEdition()) {
      var updatedEdition = edition.edition
      updatedEdition.adresse = adresse?.adresse

      val result = repo.createOrUpdateEventEdition(eventId, updatedEdition)
      SharedLogger.debug("Received response $result")

      when (result) {
        is CreateOrUpdateResult.Created -> {
          creating = true

          result.newId?.also {
            responses.add(true)
            edition.edition.id = it
          } ?: run {
            responses.add(false)
          }
        }
        is CreateOrUpdateResult.Updated -> {
          creating = false
          responses.add(result.success)
        }
      }
    }

    if (false in responses) {
      showFailureConfirmation(creating)
      return
    }

    responses.addAll(updateDays())

    finalizeCreateOrUpdate(
      SharedRelation.EventEdition(eventId, edition.id),
      responses,
      creating
    )
  }

  private fun shouldUpdateEdition(): Boolean {
    if (edition.shouldBeUpdated) {
      return true
    }

    if (adresse != null && adresse!!.shouldBeUpdated) {
      return true
    }

    return false
  }

  private suspend fun updateDays(): List<Boolean?> {
    SharedLogger.debug("Update EventEditionDays")
    var responses = mutableListOf<Boolean?>()

    days.forEach {
      if (it.shouldBeUpdated) {
        SharedLogger.debug("Create/Update day with ID ${it.id}")
        val success = repo.createOrUpdateEventEditionDay(eventId, edition.id, it.day)
        responses.add(success)
      } else {
        responses.add(null)
      }
    }

    return responses
  }
}