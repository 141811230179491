package ca.sebleclerc.admin.repositories

import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.core.models.Image
import ca.sebleclerc.network.IAPIService

interface ImagesRepositoryable {
  suspend fun assignImage(relation: SharedRelation, imageIds: String): Boolean
  suspend fun createOrUpdateImage(relation: SharedRelation, image: Image): Boolean
}

class ImagesRepository(private val apiService: IAPIService) : ImagesRepositoryable {
  override suspend fun assignImage(relation: SharedRelation, imageIds: String): Boolean {
    var success = true

    for (imageId in imageIds.split(",")) {
      imageId.toIntOrNull()?.also {
        val imageSuccess = when (relation) {
          is SharedRelation.Etablissement -> {
            apiService.associateEtablissementImage(relation.id, it)
          }
          is SharedRelation.Event -> {
            apiService.associateEventImage(relation.id, it)
          }
          is SharedRelation.EventEdition -> {
            // Should not happen for now.
            false
          }
        }
        success = success && imageSuccess
      }
    }

    return success
  }

  override suspend fun createOrUpdateImage(relation: SharedRelation, image: Image): Boolean {
    return if (image.id == 0) {
      when (relation) {
        is SharedRelation.Etablissement -> {
          apiService.createEtablissementImage(relation.id, image)
        }
        is SharedRelation.Event -> {
          apiService.createEventImage(relation.id, image)
        }
        is SharedRelation.EventEdition -> {
          // Should not happen for now.
          false
        }
      }
    } else {
      return apiService.updateImage(image.id, image)
    }
  }
}
