package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.components.LoadingView
import ca.sebleclerc.admin.models.LoadingState
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.admin.screens.views.EtablissementEditView
import ca.sebleclerc.admin.viewmodels.EtablissementEditViewModel
import ca.sebleclerc.core.models.Etablissement
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin

@Composable
fun EtablissementEditScreen(koin: Koin, placeId: Int) {
  var state by remember { mutableStateOf(LoadingState.LOADING) }
  var place by remember { mutableStateOf<Etablissement?>(null) }
  val repo = koin.get<EtablissementsRepositoryable>()

  val scope = rememberCoroutineScope()
  val reloadClosure: () -> Unit = {
    scope.launch {
      state = LoadingState.LOADING

      val placeWithId = repo.getEtablissementWithId(placeId)
      place = placeWithId
      state = if (place != null) LoadingState.VALUE else LoadingState.ERROR
    }
  }

  LaunchedEffect(true) {
    reloadClosure()
  }

  when (state) {
    LoadingState.LOADING -> {
      LoadingView()
    }
    LoadingState.VALUE -> {
      val viewModel = EtablissementEditViewModel(place!!, reloadClosure)
      EtablissementEditView(viewModel)
    }
    LoadingState.ERROR -> {
      P { Text("ERREUR!") }
    }
  }
}
