package ca.sebleclerc.network.models

import ca.sebleclerc.core.models.Etablissement
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class APIEtablissement(
  val id: Int,
  @SerialName("nom")
  val nom_etablissement: String,
  @SerialName("nom_alt")
  val nom_alternatif: String?,
  @SerialName("type")
  val id_type_etablissement: Int,
  val adresse: APIAdresse?,
  val access: APIEtablissementAccess?,
  val id_group: Int?,
  val notes: String?,
  val statut: Int,
  val contact_ids: String?,
  val image_ids: String?
)

fun APIEtablissement.toDomain(): Etablissement {
  return Etablissement(
    id,
    nom_etablissement,
    nom_alternatif,
    id_type_etablissement,
    id_group,
    notes,
    mutableListOf(),
    mutableListOf(),
    adresse?.toDomain(),
    access?.toDomain(),
    statut
  )
}

fun Etablissement.toAPI(): APIEtablissement {
  return APIEtablissement(
    id,
    nom,
    nom_alternatif,
    idTypeEtablissement,
    adresse?.toAPI(),
    access?.toAPI(),
    idGroup,
    notes,
    statut,
    null,
    null
  )
}
