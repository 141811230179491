package ca.sebleclerc.core.models

data class EtablissementAccess(
  val id: Int,
  var canDrink: Boolean?,
  var canEat: Boolean?,
  var hasBoutique: Boolean?,
  var dogFriendly: Boolean?
) {
  companion object {}
}
