package ca.sebleclerc.admin.repositories

import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.core.models.Image
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.network.models.APIGroupContact
import ca.sebleclerc.network.models.CreateOrUpdateResult
import ca.sebleclerc.network.models.toDomain
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

interface EtablissementsRepositoryable {
  val etablissements: StateFlow<Map<Int?, List<Etablissement>>>

  suspend fun loadData()
  suspend fun getEtablissementWithId(id: Int): Etablissement?
  suspend fun getEtablissementsWithIds(ids: List<Int>): List<Etablissement>
  suspend fun getEtablissementsForGroup(groupId: Int): List<Etablissement>
  suspend fun getContactsForGroup(id: Int): List<APIGroupContact>
  suspend fun createOrUpdateEtablissement(e: Etablissement): CreateOrUpdateResult
  suspend fun createOrUpdateImage(placeId: Int, image: Image): Boolean
}

class EtablissementsRepository(private val apiService: IAPIService) : EtablissementsRepositoryable {
  private var mutableEtablissements = MutableStateFlow(emptyMap<Int?, List<Etablissement>>())
  override val etablissements = mutableEtablissements.asStateFlow()

  override suspend fun loadData() {
    val places = apiService
      .getEtablissements(showAll = true, showTravel = true)
      .map { it.toDomain() }
    val groupedPlaces = places.groupBy { it.idGroup }
    mutableEtablissements.value = groupedPlaces
  }

  override suspend fun getEtablissementWithId(id: Int): Etablissement? {
    var place = apiService.getEtablissementWithId(id)?.toDomain()

    place?.id?.also { placeId ->
      place.contacts = apiService
        .getEtablissementContacts(placeId)
        .map { it.toDomain() }
        .toMutableList()
      place.images = apiService
        .getEtablissementImages(placeId)
        .map { it.toDomain() }
        .toMutableList()
    }

    return place
  }

  override suspend fun getEtablissementsWithIds(ids: List<Int>): List<Etablissement> {
    val places = mutableListOf<Etablissement?>()

    ids.forEach {
      val place = getEtablissementWithId(it)
      places.add(place)
    }

    return places.mapNotNull { it }
  }

  override suspend fun getEtablissementsForGroup(groupId: Int): List<Etablissement> {
    return apiService
      .getEtablissementsForGroup(groupId)
      .map { it.toDomain() }
  }

  override suspend fun getContactsForGroup(id: Int): List<APIGroupContact> {
    return apiService.getContactsForGroup(id)
  }

  override suspend fun createOrUpdateEtablissement(e: Etablissement): CreateOrUpdateResult {
    return if (e.id == 0) {
      SharedLogger.info("Creating the Etablissement.")
      val response = apiService.createEtablissement(e)
      CreateOrUpdateResult.Created(response)
    } else {
      SharedLogger.info("Updating Etablissement")
      val success = apiService.updateEtablissement(e.id, e)
      CreateOrUpdateResult.Updated(success)
    }
  }

  override suspend fun createOrUpdateImage(placeId: Int, image: Image): Boolean {
    return if (image.id == 0) {
      SharedLogger.info("Creating image")
      apiService.createEtablissementImage(placeId, image)
    } else {
      SharedLogger.info("Updating image")
      apiService.updateImage(image.id, image)
    }
  }
}
