package ca.sebleclerc.admin.screens.views

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import ca.sebleclerc.admin.components.AdminIDField
import ca.sebleclerc.admin.components.AdminNumberField
import ca.sebleclerc.admin.components.AdminTextField
import ca.sebleclerc.admin.components.ui.FlexContainer
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.VersionEditViewModel
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

@Composable
fun VersionEditView(viewModel: VersionEditViewModel) {
  Div({ style { height(20.px) } })

  FlexContainer {
    Row {
      AdminIDField(viewModel.version.id)
      AdminTextField("Script name", viewModel.version.scriptName)
    }

    Row {
      AdminNumberField("Major", viewModel.version.major)
      AdminNumberField("Minor", viewModel.version.minor)
      AdminNumberField("Build", viewModel.version.build)
    }

    Row {
      Button("Save") {
        viewModel.createOrUpdate()
      }
    }
  }
}
