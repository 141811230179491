package ca.sebleclerc.admin.repositories

import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Contact
import ca.sebleclerc.core.models.Event
import ca.sebleclerc.core.models.EventEdition
import ca.sebleclerc.core.models.EventEditionDay
import ca.sebleclerc.core.models.Image
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.network.models.CreateOrUpdateResult
import ca.sebleclerc.network.models.toDomain
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

interface EventsRepositoryable {
  val events: StateFlow<List<Event>>
  val editions: StateFlow<List<EventEdition>>

  suspend fun loadData()
  suspend fun getEventWithID(id: Int): Event?
  suspend fun getEventEditions(id: Int): List<EventEdition>
  suspend fun getSingleEventEdition(eventId: Int, editionId: Int): EventEdition?
  suspend fun getEventEditionDays(eventId: Int, editionId: Int): List<EventEditionDay>

  suspend fun createOrUpdateEvent(ev: Event): CreateOrUpdateResult
  suspend fun createOrUpdateContact(eventId: Int, c: Contact): Boolean
  suspend fun createOrUpdateImage(eventId: Int, image: Image): Boolean

  suspend fun assignContacts(eventId: Int, contactIds: String): Boolean
  suspend fun assignImages(eventId: Int, imageIds: String): Boolean

  suspend fun createOrUpdateEventEdition(
    eventId: Int,
    edition: EventEdition
  ) : CreateOrUpdateResult
  suspend fun createOrUpdateEventEditionDay(
    eventId: Int,
    editionId: Int,
    day: EventEditionDay
  ): Boolean
}

class EventsRepository(private val apiService: IAPIService) : EventsRepositoryable {
  private var mutableEvents = MutableStateFlow(emptyList<Event>())
  override val events = mutableEvents.asStateFlow()
  private var mutableEditions = MutableStateFlow(emptyList<EventEdition>())
  override val editions = mutableEditions.asStateFlow()

  override suspend fun loadData() {
    val events = apiService.getEvents()
    val editions = apiService.getEditions()
    mutableEvents.value = events.map { it.toDomain() }
    mutableEditions.value = editions.map { it.toDomain() }
  }

  override suspend fun getEventWithID(id: Int): Event? {
    var event = apiService.getEventWithId(id)?.toDomain()

    val contacts = apiService.getEventContacts(id)
    event?.contacts = contacts.map { it.toDomain() }

    val images = apiService.getEventImages(id)
    event?.images = images.map { it.toDomain() }

    return event
  }

  override suspend fun getEventEditions(id: Int): List<EventEdition> {
    val editions = apiService.getEventEditions(id)
    return editions.map { it.toDomain() }
  }

  override suspend fun getSingleEventEdition(eventId: Int, editionId: Int): EventEdition? {
    val editions = apiService.getSingleEventEdition(eventId, editionId)
    return editions?.toDomain()
  }

  override suspend fun getEventEditionDays(eventId: Int, editionId: Int): List<EventEditionDay> {
    val days = apiService.getEventEditionDays(eventId, editionId)
    return days.map { it.toDomain() }
  }

  override suspend fun createOrUpdateEvent(ev: Event): CreateOrUpdateResult {
    return if (ev.id == 0) {
      SharedLogger.debug("Creating Event")
      val response = apiService.createEvent(ev)
      CreateOrUpdateResult.Created(response)
    } else {
      SharedLogger.debug("Updating Event")
      val success = apiService.updateEvent(ev.id, ev)
      CreateOrUpdateResult.Updated(success)
    }
  }

  override suspend fun createOrUpdateContact(eventId: Int, c: Contact): Boolean {
    return if (c.id == 0) {
      SharedLogger.debug("Creating contact")
      apiService.createEventContact(eventId, c)
    } else {
      SharedLogger.debug("Updating contact")
      apiService.updateContact(c.id, c)
    }
  }

  override suspend fun createOrUpdateImage(eventId: Int, image: Image): Boolean {
    return if (image.id == 0) {
      SharedLogger.debug("Creating image")
      apiService.createEventImage(eventId, image)
    } else {
      SharedLogger.debug("Updating image")
      apiService.updateImage(image.id, image)
    }
  }

  override suspend fun assignContacts(eventId: Int, contactIds: String): Boolean {
    var success = true

    for (contactId in contactIds.split(",")) {
      contactId.toIntOrNull()?.also {
        success = success && apiService.associateEventContact(eventId, it)
      }
    }

    return success
  }

  override suspend fun assignImages(eventId: Int, imageIds: String): Boolean {
    var success = true

    for (imageId in imageIds.split(",")) {
      imageId.toIntOrNull()?.also {
        success = success && apiService.associateEventImage(eventId, it)
      }
    }

    return success
  }

  override suspend fun createOrUpdateEventEdition(
    eventId: Int,
    edition: EventEdition
  ): CreateOrUpdateResult {
    return if (edition.id == 0) {
      val newId = apiService.createEventEdition(eventId, edition)
      CreateOrUpdateResult.Created(newId)
    } else {
      val success = apiService.updateEventEdition(edition.id, edition)
      CreateOrUpdateResult.Updated(success)
    }
  }

  override suspend fun createOrUpdateEventEditionDay(
    eventId: Int,
    editionId: Int,
    day: EventEditionDay
  ): Boolean {
    return if (day.id == 0) {
      val newId = apiService.createEventEditionDay(eventId, editionId, day)
      newId != null
    } else {
      return apiService.updateEventEditionDay(day.id, day)
    }
  }
}