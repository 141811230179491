package ca.sebleclerc.admin.repositories

import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.core.models.Contact
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.network.IAPIService

interface ContactsRepositoryable {
  suspend fun assignContact(relation: SharedRelation, contactIds: String): Boolean
  suspend fun createContact(contact: Contact): Int?
  suspend fun updateContact(contact: Contact): Boolean
  suspend fun createOrUpdateContact(relation: SharedRelation, contact: Contact): Boolean
}

class ContactsRepository(private val apiService: IAPIService) : ContactsRepositoryable {
  override suspend fun assignContact(relation: SharedRelation, contactIds: String): Boolean {
    var success = true

    for (contactId in contactIds.split(",")) {
      contactId.toIntOrNull()?.also {
        val assocSuccess = when (relation) {
          is SharedRelation.Etablissement -> {
            apiService.associateEtablissementContact(relation.id, it)
          }
          is SharedRelation.Event -> {
            apiService.associateEventContact(relation.id, it)
          }
          is SharedRelation.EventEdition -> {
            // Should not happen for now
            false
          }
        }
        success = success && assocSuccess
      }
    }

    return success
  }

  override suspend fun createContact(contact: Contact): Int? {
    return apiService.createContact(contact)
  }

  override suspend fun updateContact(contact: Contact): Boolean {
    return apiService.updateContact(contact.id, contact)
  }

  override suspend fun createOrUpdateContact(relation: SharedRelation, contact: Contact): Boolean {
    return if (contact.id == 0) {
      when (relation) {
        is SharedRelation.Etablissement -> {
          apiService.createEtablissementContact(relation.id, contact)
        }
        is SharedRelation.Event -> {
          apiService.createEventContact(relation.id, contact)
        }
        is SharedRelation.EventEdition -> {
          // Should not happen for now
          false
        }
      }
    } else {
      return apiService.updateContact(contact.id, contact)
    }
  }
}
