package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Modal
import ca.sebleclerc.admin.components.ui.Row
import ca.sebleclerc.admin.viewmodels.SharedViewModel
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput

@Composable
fun ContactsEditSection(viewModel: SharedViewModel) {
  SubtitleView("Contacts")

  Row {
    Button("Add Contact") {
      viewModel.didClickAddContact()
    }

    Button("Assign Contact") {
      viewModel.didClickAssignContact()
    }
  }

  viewModel
    .contacts
    .sortedWith(compareBy({ it.idTypeContact.value }, { it.id }))
    .forEach {
      Row {
        ContactEditView(it)
      }
    }

  if (viewModel.showContactModal) {
    Modal(
      "Assign contacts",
      onDismissRequest = { viewModel.showContactModal = false },
      footer = {
        Button("Assign") { viewModel.didFinishAssignContact() }
        Button("Close") { viewModel.showContactModal = false }
      }
    ) {
      Span({ style { paddingRight(20.px) } }) { Text("Contact IDs:") }
      TextInput(value = viewModel.modalContactIds, attrs = {
        onInput {
          viewModel.modalContactIds = it.value
        }
      })
    }
  }
}