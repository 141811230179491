package ca.sebleclerc.admin

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.HashRouter
import ca.sebleclerc.admin.components.Header
import ca.sebleclerc.admin.screens.EtablissementCreateScreen
import ca.sebleclerc.admin.screens.EtablissementEditScreen
import ca.sebleclerc.admin.screens.EtablissementsGroupScreen
import ca.sebleclerc.admin.screens.EtablissementsListScreen
import ca.sebleclerc.admin.screens.EventCreateScreen
import ca.sebleclerc.admin.screens.EventEditScreen
import ca.sebleclerc.admin.screens.EventEditionCreateScreen
import ca.sebleclerc.admin.screens.EventEditionEditScreen
import ca.sebleclerc.admin.screens.EventsListScreen
import ca.sebleclerc.admin.screens.MainScreen
import ca.sebleclerc.admin.screens.ValidationsListScreen
import ca.sebleclerc.admin.screens.VersionCreateScreen
import ca.sebleclerc.admin.screens.VersionListScreen
import org.koin.core.Koin

@Composable
fun Router(koin: Koin) {
  Header(koin)
  HashRouter(initPath = "/") {
    route("/") {
      MainScreen()
    }

    route("/etablissements") {
      route("/new") {
        EtablissementCreateScreen()
      }

      route("/groups") {
        int {
          EtablissementsGroupScreen(koin, it)
        }
      }

      int {
        EtablissementEditScreen(koin, it)
      }

      noMatch {
        EtablissementsListScreen(koin)
      }
    }

    route("/events") {
      route("/new") {
        EventCreateScreen()
      }

      int { eventId ->
        route("/editions") {
          route("/new") {
            EventEditionCreateScreen(eventId)
          }
          int { editionId ->
            EventEditionEditScreen(koin, eventId, editionId)
          }
        }

        noMatch {
          EventEditScreen(koin, eventId)
        }
      }

      noMatch {
        EventsListScreen(koin)
      }
    }

    route("/versions") {
      route("/new") {
        VersionCreateScreen()
      }

      noMatch {
        VersionListScreen(koin)
      }
    }

    route("/validations") {
      ValidationsListScreen(koin)
    }
  }
}
