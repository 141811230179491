package ca.sebleclerc.admin.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.runtime.toMutableStateList
import ca.sebleclerc.admin.models.EditableAccess
import ca.sebleclerc.admin.models.EditableAdresse
import ca.sebleclerc.admin.models.EditableContact
import ca.sebleclerc.admin.models.EditableEtablissement
import ca.sebleclerc.admin.models.EditableImage
import ca.sebleclerc.admin.models.SharedRelation
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.network.models.CreateOrUpdateResult
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class EtablissementEditViewModel(etablissement: Etablissement, reload: () -> Unit) : SharedViewModel(reload), KoinComponent {
  private val repo: EtablissementsRepositoryable by inject()

  var place by mutableStateOf(EditableEtablissement(etablissement))
  var access by mutableStateOf<EditableAccess?>(null)

  init {
    etablissement.adresse?.also {
      adresse = EditableAdresse(it)
    }

    etablissement.access?.also {
      access = EditableAccess(it)
    }

    contacts.addAll(etablissement.contacts.map { EditableContact(it) })
    images.addAll(etablissement.images.map { EditableImage(it) })
  }

  // Access

  fun addAccess() {
    access = EditableAccess.createNew()
  }

  suspend fun createOrUpdate() {
    SharedLogger.debug("createOrUpdate")
    var responses = mutableListOf<Boolean?>()
    var creating = false

    if (shouldUpdateEtablissement()) {
      var updatedPlace = place.etablissement
      updatedPlace.access = access?.access
      updatedPlace.adresse = adresse?.adresse

      val result = repo.createOrUpdateEtablissement(updatedPlace)
      SharedLogger.debug("Received response $result")

      when (result) {
        is CreateOrUpdateResult.Created -> {
          creating = true

          result.newId?.also {
            responses.add(true)
            place.etablissement.id = it
          } ?: run {
            responses.add(false)
          }
        }

        is CreateOrUpdateResult.Updated -> {
          creating = false
          responses.add(result.success)
        }
      }
    }

    if (false in responses) {
      showFailureConfirmation(creating = creating)
      return
    }

    responses.addAll(updateContacts(SharedRelation.Etablissement(place.id)))

    if (false in responses) {
      showFailureConfirmation(creating = creating)
      return
    }

    responses.addAll(updateImages(SharedRelation.Etablissement(place.id)))

    finalizeCreateOrUpdate(SharedRelation.Etablissement(place.id), responses, creating)
  }

  private fun shouldUpdateEtablissement(): Boolean {
    if (place.shouldBeUpdated) {
      return true
    }

    if (access != null && access!!.shouldBeUpdated) {
      return true
    }

    if (adresse != null && adresse!!.shouldBeUpdated) {
      return true
    }

    return false
  }
}
