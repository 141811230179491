package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.components.ui.Row
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

@Composable
fun SubtitleView(text: String) {
  Row {
    H3 {
      Text(text)
    }
  }
}
