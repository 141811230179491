package ca.sebleclerc.admin.repositories

import ca.sebleclerc.core.SharedLogger
import ca.sebleclerc.core.models.Version
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.network.models.CreateOrUpdateResult
import ca.sebleclerc.network.models.toDomain
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

interface VersionsRepositoryable {
  val versions: StateFlow<List<Version>>

  suspend fun loadData()
  suspend fun createOrUpdateVersion(v: Version): CreateOrUpdateResult
}

class VersionsRepository(private val apiService: IAPIService) : VersionsRepositoryable {
  private var mutableVersions = MutableStateFlow(emptyList<Version>())
  override val versions = mutableVersions.asStateFlow()

  override suspend fun loadData() {
    val versions = apiService.getAllVersions()
    mutableVersions.value = versions.map { it.toDomain() }
  }

  override suspend fun createOrUpdateVersion(v: Version): CreateOrUpdateResult {
    return if (v.id == 0) {
      SharedLogger.info("Creating version")
      val response = apiService.createVersion(v)
      CreateOrUpdateResult.Created(response)
    } else {
      SharedLogger.info("Updating version")
//      val success = apiService.upd
      // Not implemented
      CreateOrUpdateResult.Updated(false)
    }
  }
}
