package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.components.LoadingView
import ca.sebleclerc.admin.models.LoadingState
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.admin.screens.views.EtablissementsGroupView
import ca.sebleclerc.admin.viewmodels.EtablissementsGroupViewModel
import ca.sebleclerc.core.models.Etablissement
import ca.sebleclerc.network.models.APIGroupContact
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.koin.core.Koin

@Composable
fun EtablissementsGroupScreen(koin: Koin, idGroup: Int) {
  var state by remember { mutableStateOf(LoadingState.LOADING) }
  var places = remember { mutableStateListOf<Etablissement>() }
  var contacts = remember { mutableStateListOf<APIGroupContact>() }

  val repo = koin.get<EtablissementsRepositoryable>()
  val scope = rememberCoroutineScope()
  val reloadClosure: () -> Unit = {
    scope.launch {
      state = LoadingState.LOADING

      val repoPlaces = repo.getEtablissementsForGroup(idGroup)
      places.clear()
      places.addAll(repoPlaces)

      val repoContacts = repo.getContactsForGroup(idGroup)
      contacts.clear()
      contacts.addAll(repoContacts)

      state = if (repoPlaces.isNotEmpty()) LoadingState.VALUE else LoadingState.ERROR
    }
  }

  LaunchedEffect(true) {
    reloadClosure()
  }

  when (state) {
    LoadingState.LOADING -> {
      LoadingView()
    }
    LoadingState.VALUE -> {
      val viewModel = EtablissementsGroupViewModel(places, contacts, reloadClosure)
      EtablissementsGroupView(viewModel)
    }
    LoadingState.ERROR -> {
      P { Text("Erreur!!") }
    }
  }
}