package ca.sebleclerc.admin.models

import ca.sebleclerc.admin.models.editables.EditableField
import ca.sebleclerc.admin.models.editables.EditableModel
import ca.sebleclerc.core.models.EventEditionDay

class EditableEventEditionDay(var day: EventEditionDay, isNew: Boolean = false) : EditableModel(isNew) {
  val rawDayOfWeek = EditableField(day.rawDayOfWeek) { day.rawDayOfWeek = it }

  val startTimeHour = EditableField(day.startTimeHour) { day.startTimeHour = it }
  val startTimeMinute = EditableField(day.startTimeMinute) { day.startTimeMinute = it }

  val endTimeHour = EditableField(day.endTimeHour) { day.endTimeHour = it }
  val endTimeMinute = EditableField(day.endTimeMinute) { day.endTimeMinute = it }

  init {
    fields = listOf(
      rawDayOfWeek,
      startTimeHour,
      startTimeMinute,
      endTimeHour,
      endTimeMinute
    )
  }

  val id: Int
    get() { return day.id }

  companion object {
    fun createNew(): EditableEventEditionDay {
      return EditableEventEditionDay(
        EventEditionDay(
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ),
        isNew = true
      )
    }
  }
}