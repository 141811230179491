package ca.sebleclerc.admin.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import ca.sebleclerc.admin.components.Icon
import ca.sebleclerc.admin.components.IconType
import ca.sebleclerc.admin.components.LoadingView
import ca.sebleclerc.admin.components.TitleView
import ca.sebleclerc.admin.models.LoadingState
import ca.sebleclerc.network.IAPIService
import ca.sebleclerc.network.models.APIValidation
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Tr
import org.koin.core.Koin

@Composable
fun ValidationsListScreen(koin: Koin) {
  var state by remember { mutableStateOf(LoadingState.LOADING) }
  var validations = remember { mutableStateListOf<APIValidation>() }
  val apiService = koin.get<IAPIService>()

  LaunchedEffect(true) {
    val aValidations = apiService.getAllValidations()
    validations.addAll(aValidations)
    state = if (validations.isEmpty()) LoadingState.ERROR else LoadingState.VALUE
  }

  when (state) {
    LoadingState.LOADING -> {
      LoadingView()
    }
    LoadingState.ERROR -> {
      P { Text("ERREUR!!") }
    }
    LoadingState.VALUE -> {
      ValidationsListView(validations)
    }
  }
}

@Composable
private fun ValidationsListView(validations: List<APIValidation>) {
  TitleView("Validations")

  Table({ style {
    marginLeft(20.px)
    marginRight(20.px)
    marginBottom(50.px)
  } }) {
    Tr {
      Th { Text("ID") }
      Th { Text("Title") }
      Th { Text("Validated") }
      Th { Text("Details") }
    }

    validations.forEach {
      Tr {
        Td { Text(it.id) }
        Td { Text(it.title) }
        Td({ style { textAlign("center") } }) { Icon(if (it.errors.isEmpty()) IconType.PASSED else IconType.DELETED) }
        Td { Text(it.errors) }
      }
    }
  }
}