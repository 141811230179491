package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.editables.EditableField
import dev.petuska.kmdc.textfield.MDCTextField
import org.jetbrains.compose.web.attributes.readOnly

@Composable
fun AdminTextField(title: String, field: EditableField<String>, readOnly: Boolean = false) {
  MDCTextField(
    value = field.value,
    label = title,
    attrs = {
      if (readOnly) { readOnly() }
      apply { attr("data-1p-ignore", "true") }
      onInput { field.setValue(it.value) }
    }
  )
}

@Composable
fun AdminNullTextField(title: String, field: EditableField<String?>, readOnly: Boolean = false) {
  MDCTextField(
    label = title,
    value = field.value ?: "",
    attrs = {
      if (readOnly) { readOnly() }
      apply { attr("data-1p-ignore", "true") }
      onInput { if (it.value.isEmpty()) field.setValue(null) else field.setValue(it.value) }
    }
  )
}

@Composable
fun AdminNumberField(title: String, field: EditableField<Int>, readOnly: Boolean = false) {
  MDCTextField(
    label = title,
    value = field.value.toString(),
    attrs = {
      if (readOnly) { readOnly() }
      apply { attr("data-1p-ignore", "true") }
      onInput { field.setValue(it.value.toIntOrNull() ?: 0) }
      attr("type", "number")
    }
  )
}

@Composable
fun AdminNullNumberField(title: String, field: EditableField<Int?>, readOnly: Boolean = false) {
  MDCTextField(
    label = title,
    value = field.value?.toString() ?: "",
    attrs = {
      if (readOnly) { readOnly() }
      apply { attr("data-1p-ignore", "true") }
      attr("type", "number")
      onInput {
        if (it.value.isEmpty()) {
          field.setValue(null)
        } else {
          field.setValue(it.value.toIntOrNull())
        }
      }
    }
  )
}

@Composable
fun AdminIDField(value: Int) {
  MDCTextField(
    label = "ID",
    value = value.toString(),
    attrs = {
      readOnly()
      apply { attr("data-1p-ignore", "true") }
      attr("type", "number")
    }
  )
}
