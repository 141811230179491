package ca.sebleclerc.admin.models

import ca.sebleclerc.core.models.Etablissement

fun Etablissement.Companion.createNew(): Etablissement {
  return Etablissement(
    0,
    "",
    null,
    0,
    null,
    null,
    mutableListOf(),
    mutableListOf(),
    null,
    null,
    1
  )
}
