package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableContact
import ca.sebleclerc.core.models.TypeContact
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun ContactEditView(contact: EditableContact) {
  AdminIDField(contact.id)
  AdminSelect("Type", contact.idTypeContact, typeContactValues)
  AdminTextField("Adresse", contact.adresse)
  AdminNullTextField("Display Label", contact.displayLabel)
  AdminNullTextField("Externe", contact.idExterne)
  AdminSelect("Actif", contact.actif, actifSelectValues)
  if (contact.showOpenLink) {
    A(href = contact.adresse.value, { target(ATarget.Blank) }) {
      Span({ classes("material-icons") }) {
        Text("open_in_new")
      }
    }
  }
}

private val EditableContact.showOpenLink: Boolean
  get() = when (typeContact) {
    TypeContact.Facebook -> true
    TypeContact.SiteWeb -> true
    TypeContact.Twitter -> true
    TypeContact.Instagram -> true
    else -> false
  }
