package ca.sebleclerc.core.models

enum class ImageType(private val value: Int) {
  Unknown(0),
  Logo(1);

  companion object {
    private val map = ImageType.values().associateBy(ImageType::value)

    fun valueOf(value: Int): ImageType {
      return map[value] ?: ImageType.Unknown
    }
  }
}
