package ca.sebleclerc.admin.components

import androidx.compose.runtime.Composable
import ca.sebleclerc.admin.models.EditableEventEdition
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun EventEditionDisplay(edition: EditableEventEdition) {
  Text(edition.year.value.toString())
  A(href = "#/events/${edition.idEvent.value}/editions/${edition.id}") {
    Span({ classes("material-icons") }) {
      Text("edit_in_new")
    }
  }
}
