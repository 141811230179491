package ca.sebleclerc.admin.di

import ca.sebleclerc.admin.repositories.AdressesRepository
import ca.sebleclerc.admin.repositories.AdressesRepositoryable
import ca.sebleclerc.admin.repositories.ContactsRepository
import ca.sebleclerc.admin.repositories.ContactsRepositoryable
import ca.sebleclerc.admin.repositories.EtablissementsRepository
import ca.sebleclerc.admin.repositories.EtablissementsRepositoryable
import ca.sebleclerc.admin.repositories.EventsRepository
import ca.sebleclerc.admin.repositories.EventsRepositoryable
import ca.sebleclerc.admin.repositories.ImagesRepository
import ca.sebleclerc.admin.repositories.ImagesRepositoryable
import ca.sebleclerc.admin.repositories.VersionsRepository
import ca.sebleclerc.admin.repositories.VersionsRepositoryable
import ca.sebleclerc.admin.viewmodels.HeaderViewModel
import ca.sebleclerc.core.Environment
import ca.sebleclerc.network.APIService
import ca.sebleclerc.network.IAPIService
import org.koin.core.context.startKoin
import org.koin.dsl.module

fun initKoin() = startKoin {
  modules(servicesModule(), repoModule(), viewModels())
}

fun servicesModule() = module {
  single { Environment.Prod }
  single<IAPIService> { APIService(get()) }
}

fun repoModule() = module {
  single<AdressesRepositoryable> { AdressesRepository(get()) }
  single<ContactsRepositoryable> { ContactsRepository(get()) }
  single<EtablissementsRepositoryable> { EtablissementsRepository(get()) }
  single<EventsRepositoryable> { EventsRepository(get()) }
  single<ImagesRepositoryable> { ImagesRepository(get()) }
  single<VersionsRepositoryable> { VersionsRepository(get()) }
}

fun viewModels() = module {
  single { HeaderViewModel(get()) }
}
